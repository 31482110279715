import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'rideshare_signed_in_period',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Rideshare Period',
    short: 'Rideshare Period',
  },
  options: [
    {value: 'period_zero', label: 'PERIOD 0 - APP OFF'},
    {value: 'period_one', label: 'PERIOD 1 - SIGNED ON ONLY'},
    {value: 'period_two', label: 'PERIOD 2 - ENROUTE TO PICKUP'},
    {value: 'period_three', label: 'PERIOD 3 - PASSENGER INSIDE'},
  ],
});
