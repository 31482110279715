import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/noData.profile.api';

// Events
import event from '../events/updated.event.accident';

export default async (accidentId) => {
  const response = await f3tch(`/accidents/dol/${accidentId}/`)
    .profile(profile())
    .delete();
  event.publish(accidentId);
  return response;
};
