// accident redux actions
import {updateAccident as updateAccidentAct} from '../../../accident/redux/actions';

// accidentClaim api
import updateAccidentClaimApi from '../../api/update.api.accidentClaim';

// accidentClaim attributes
import followupDateAttribute from '../../attributes/followup_datetime.attribute.accidentClaim';

// date lib
import convertDateTimeToDate from '../../../date/lib/convertDateTimeToDate.lib.date';
import convertDateToDateTime from '../../../date/lib/convertDateToDateTime.lib.date';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class FollowupDateContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
  };

  state = {
    followup_datetime: followupDateAttribute(''),
  };

  componentDidMount() {
    const {accident} = this.props;
    this.setState({
      followup_datetime: followupDateAttribute(
        accident?.dolclaim?.followup_datetime || ''
      ),
    });
  }

  change = async (followup_datetime) => {
    const {accident, dispatch} = this.props;
    this.setState({followup_datetime});

    try {
      const accidentClaimBody = {
        followup_datetime: followup_datetime.api.format() || null,
      };
      if (!!accidentClaimBody.followup_datetime)
        accidentClaimBody.followup_datetime = convertDateToDateTime(
          accidentClaimBody.followup_datetime
        );
      const updatedAccidentClaim = await updateAccidentClaimApi({
        accidentId: accident.id,
        claim: accidentClaimBody,
      });
      if (!this.mounted) return;
      const updatedAccident = {
        ...accident,
        dolclaim: updatedAccidentClaim,
      };
      dispatch(updateAccidentAct(updatedAccident));
    } catch (error) {
      // do nothing just don't fail
    }
  };

  render() {
    const {followup_datetime} = this.state;
    return (
      <AttributeInput
        value={convertDateTimeToDate(followup_datetime)}
        onChange={(value, ...attributes) =>
          this.change(convertDateToDateTime(value), ...attributes)
        }
        size="small"
      />
    );
  }
}

export default connect()(FollowupDateContainer);
