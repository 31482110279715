// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// accident api
import updateAccidentStateApi from '../../api/updateState.api.accident';

// accident lib
import getAccidentStateObject from '../../lib/getStateObject.lib.accident';

// accident redux actions
import {updateAccident as updateAccidentAction} from '../../redux/actions';

// accident states
import fileReviewAccidentState from '../../state/file_review.state.accident';

// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import SimpleMenu from '@matthahn/sally-ui/lib/components/SimpleMenu/SimpleMenu';
import Tag from '@matthahn/sally-ui/lib/components/Tag/Tag';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// react redux
import {connect} from 'react-redux';

class AccidentStateManagerContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    dispatch: PropTypes.func,
    manualStates: PropTypes.array,
  };

  state = {
    visible: false,
    loading: false,
  };

  canEditState = () => {
    const {accident} = this.props;
    return accident?.accidentstate?.auto_state === fileReviewAccidentState.key;
  };

  show = () => {
    if (!this.canEditState()) return;
    this.setState({visible: true});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async (accidentState) => {
    const {accident, dispatch} = this.props;
    const {visible, loading} = this.state;

    if (
      !visible ||
      loading ||
      accident?.accidentstate?.manual_state === accidentState
    )
      return;

    this.setState({loading: true});

    try {
      await updateAccidentStateApi(accident.id, {
        manual_state: accidentState,
      });
      const updatedAccident = {
        ...accident,
        accidentstate: {
          ...accident.accidentstate,
          manual_state: accidentState,
        },
      };
      dispatch(updateAccidentAction(updatedAccident));
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {accident, manualStates} = this.props;
    const {loading, visible} = this.state;
    return (
      <Fragment>
        <Tag theme={getAccidentStateObject(accident).color} onClick={this.show}>
          {getAccidentStateObject(accident).label}
        </Tag>
        <Modal
          title="Edit Accident State"
          visible={visible}
          onClose={this.hide}
          size="small"
        >
          {(Content) => (
            <Content padding="none" noBorder>
              {loading ? (
                <ModalLoader />
              ) : (
                <SimpleMenu
                  selected={[accident?.accidentstate?.manual_state]}
                  options={[...manualStates]}
                  onSelect={this.save}
                  noDataLabel="No data available"
                />
              )}
            </Content>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  accident: state.accident.accident,
  manualStates: state.accident.manualStates,
}))(AccidentStateManagerContainer);
