import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import number from '@matthahn/sally-fw/lib/type/types/number.type';

export default attribute({
  type: NUMBER,
  attribute: 'rf_liability',
  display: number,
  input: number,
  api: number,
  label: {
    default: 'Liability',
    short: 'Liability',
  },
});
