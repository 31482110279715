import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// accident components
import AccidentClaimDashboard from '../../components/AccidentClaimDashboard/AccidentClaimDashboard';

// accident containers
// import AccidentTransactionsContainer from '../AccidentTransactionsContainer/AccidentTransactionsContainer';

// accidentClaim containers
import LiabilityFinancialsContainer from '../../../accidentClaim/containers/LiabilityFinancialsContainer/LiabilityFinancialsContainer';
import RecoveryFinancialsContainer from '../../../accidentClaim/containers/RecoveryFinancialsContainer/RecoveryFinancialsContainer';

// accidentNote containers
import AccidentNotesContainer from '../../../accidentNote/containers/AccidentNotesContainer/AccidentNotesContainer';

// Api
import updateAccidentApi from '../../api/update.api.accident';

// Actions
import {updateAccident as updateAccidentAct} from '../../redux/actions';
import {set as setInfoAct} from '../AccidentInfoContainer/redux/actions';

// Attributes
import * as accidentAttributes from '../../attributes';
import objectToAttributes from '@matthahn/sally-fw/lib/attribute/objectToAttributes';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';
import AccidentInsuranceCard from '../../components/AccidentInsuranceCard/AccidentInsuranceCard';

// driver routes
import driverRoute from '../../../driver/pages/DriverPage/route';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Libs
import {lib} from '@matthahn/sally-ui';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';

// Prep
import updatePrep from '../../preparation/update.preparation.accident';
import updateInsurancePrep from '../../preparation/updateInsurance.preparation.accident';

// react router
import {withRouter} from 'react-router-dom';

// Alerts
const {alert} = lib;

class AccidentClaimsContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    history: PropTypes.object,
  };

  static attributes = [
    'appointment_time',
    'camera_unplugged',
    'datetime_of_loss',
    'description',
    'driver',

    'settlement_denied',
    'settlement_pending_amount',
    'settlement_reason',

    'injuries',
    'injuries_description',

    'insurance_adjuster_name',
    'insurance_adjuster_contact_info_email',
    'insurance_adjuster_contact_info_phone',
    'insurance_carrier',
    'insurance_claim_number',

    'police_called',
    'police_report_number',
    'police_report_taken',
    'dol_city',
    'dol_location',
    'dol_state',
    'number_of_people_in_vehicle',
    'rental',
    'rideshare_insurance_adjuster_contact_info_email',
    'rideshare_insurance_adjuster_contact_info_phone',
    'rideshare_insurance_adjuster_name',
    'rideshare_insurance_carrier',
    'rideshare_insurance_claim_number',
    'rideshare_signed_in_period',
    'rideshare_signed_in_service',
    'rideshare_signed_in',
    'state',
    'tow_required',
    'vehicle',
  ];

  static accidentAttributes = {
    ...accidentAttributes,
  };

  state = {
    loading: false,
    accident: null,
    errors: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({
      accident: objectToAttributes({
        object: this.props.accident,
        attributes: {
          ...this.constructor.accidentAttributes,
        },
        convert: this.constructor.attributes,
      }),
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  change = setUnsavedChanges(
    (value, key, errors) => {
      const {accident} = this.state;
      const updatedAccident = {...accident, [key]: value};
      if (
        key === accidentAttributes.settlement_pending_amount.attribute &&
        value.api.format() > 0
      )
        updatedAccident.settlement_denied =
          accidentAttributes.settlement_denied(false);
      this.setState({accident: updatedAccident, errors});
    },
    () => this.state.loading
  );

  saveAccident = () => this.save({prep: updatePrep});

  saveAccidentInsurance = () => this.save({prep: updateInsurancePrep});

  save = async ({prep = updatePrep} = {}) => {
    const {
      dispatch,
      accident: {id},
    } = this.props;
    const {
      loading,
      accident: {ssn, ...attributes},
    } = this.state;

    if (loading) return;

    this.setState({loading: true});
    dispatch(setInfoAct({saving: true}));

    try {
      const accident = await prep(attributes);
      const response = await updateAccidentApi(id, accident);
      if (this.props.accident.id !== id) return;
      clearUnsavedChanges();
      dispatch(setInfoAct({saving: false}));
      if (!this.mounted) return;
      dispatch(updateAccidentAct(response));
      alert.success('Accident info saved');
      this.setState({loading: false});
    } catch (error) {
      const {message, fields} = parseError(error);
      alert.error(message);
      dispatch(setInfoAct({saving: false}));
      if (!this.mounted) return;
      this.setState({loading: false, errors: fields});
    }
  };

  refreshAttributes = (attributes) => {
    const {accident} = this.state;
    const newAccidentAttributes = objectToAttributes({
      object: this.props.accident,
      attributes: {
        ...this.constructor.accidentAttributes,
      },
      convert: attributes,
    });

    this.setState({accident: {...accident, ...newAccidentAttributes}});
  };

  goToDriver = () =>
    this.props.history.push(driverRoute(this.props.accident?.driver?.id));

  render() {
    const {accident: rawAccident} = this.props;
    const {loading, accident, errors} = this.state;
    return !accident ? null : (
      <SubPage>
        <Row margin>
          <Column size={1 / 3} m={1 / 2} margin>
            <Row margin>
              <Column>
                <RecoveryFinancialsContainer accident={rawAccident} />
              </Column>
            </Row>
            <Row margin>
              <Column>
                <LiabilityFinancialsContainer accident={rawAccident} />
              </Column>
            </Row>
            <Row margin>
              <Column>
                <AccidentInsuranceCard
                  loading={loading}
                  errors={errors}
                  onChange={this.change}
                  onSave={this.saveAccidentInsurance}
                  {...accident}
                  accident={rawAccident}
                />
              </Column>
            </Row>
          </Column>
          <Column size={2 / 3} m={1} margin>
            <Row margin>
              <Column>
                <AccidentClaimDashboard
                  accident={rawAccident}
                  onDriver={this.goToDriver}
                />
              </Column>
            </Row>
            <Row margin>
              <Column>
                <AccidentNotesContainer title="History" />
              </Column>
            </Row>
            {/* <Row margin>
              <Column>
                <AccidentTransactionsContainer />
              </Column>
            </Row> */}
          </Column>
        </Row>
      </SubPage>
    );
  }
}

export default connect((state) => ({
  accident: state.accident.accident,
}))(withRouter(AccidentClaimsContainer));
