import {fileName, folder, date} from '../../../document';

export default folder({
  defaultFileName: 'Demand Letter',
  // DEMAND LETTER - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `DEMAND LETTER - ${accident.driver?.first_name}_${
        accident.driver?.last_name
      } - ${
        accident?.medallion?.medallion_number || accident?.vehicle?.plate
      } - ${date()}`
  ),
  folderName: 'Demand Letters',
  type: 'accidentDemandLetter',
});
