import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (noChargeReason) =>
  f3tch(`/accidents/dol/nocharge_reason/`)
    .profile(profile())
    .body(noChargeReason)
    .post();
