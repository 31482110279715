// accidentParty api
import updateAccidentPartyApi from '../../api/update.api.accidentParty';

// accidentParty attributes
import insuranceAdjusterContactInfoEmailAttribute from '../../attributes/insurance_adjuster_contact_info_email.attribute.accidentParty';
import insuranceAdjusterContactInfoPhoneAttribute from '../../attributes/insurance_adjuster_contact_info_phone.attribute.accidentParty';
import insuranceAdjusterNameAttribute from '../../attributes/insurance_adjuster_name.attribute.accidentParty';
import insuranceCarrierAttribute from '../../attributes/insurance_carrier.attribute.accidentParty';
import insuranceClaimNumberAttribute from '../../attributes/insurance_claim_number.attribute.accidentParty';
import partyTypeAttribute from '../../attributes/party_type.attribute.accidentParty';
// import ridesharePlatformAttribute from '../../attributes/rideshare_platform.attribute.accidentParty';

// accidentPart components
import AccidentPartyEditor from '../../components/AccidentPartyEditor/AccidentPartyEditor';

// accidentParty events
import saveAccidentPartyEvent from '../../events/save.event.accidentParty';

// accidentParty lib
import isAccidentPartyOfTypeRideshare from '../../lib/isRideshareType.lib.accidentParty';

// accidentParty preparations
import updateAccidentPartyPreparation from '../../preparations/update.preparation.accidentParty';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class EditAccidentPartyContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    accidentParty: PropTypes.object,
    onSaved: PropTypes.func,
    subscribe: PropTypes.func,
  };

  state = {
    insurance_adjuster_contact_info_email:
      insuranceAdjusterContactInfoEmailAttribute(''),
    insurance_adjuster_contact_info_phone:
      insuranceAdjusterContactInfoPhoneAttribute(''),
    insurance_adjuster_name: insuranceAdjusterNameAttribute(''),
    insurance_carrier: insuranceCarrierAttribute(''),
    insurance_claim_number: insuranceClaimNumberAttribute(''),
    party_type: partyTypeAttribute(''),
    // rideshare_platform: ridesharePlatformAttribute(''),
    saving: false,
  };

  componentDidMount() {
    this.initialize();
    this.props.subscribe(saveAccidentPartyEvent.subscribe(this.save));
  }

  initialize = () => {
    const {accidentParty} = this.props;
    this.setState({
      insurance_adjuster_contact_info_email:
        insuranceAdjusterContactInfoEmailAttribute(
          accidentParty?.insurance_adjuster_contact_info_email || ''
        ),
      insurance_adjuster_contact_info_phone:
        insuranceAdjusterContactInfoPhoneAttribute(
          accidentParty?.insurance_adjuster_contact_info_phone || ''
        ),
      insurance_adjuster_name: insuranceAdjusterNameAttribute(
        accidentParty?.insurance_adjuster_name || ''
      ),
      insurance_carrier: insuranceCarrierAttribute(
        accidentParty?.insurance_carrier || ''
      ),
      insurance_claim_number: insuranceClaimNumberAttribute(
        accidentParty?.insurance_claim_number || ''
      ),
      party_type: partyTypeAttribute(accidentParty?.party_type || ''),
      // rideshare_platform: ridesharePlatformAttribute(
      //   accidentParty?.rideshare_platform || ''
      // ),
    });
  };

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {accident, accidentParty, onSaved} = this.props;
    const {
      insurance_adjuster_contact_info_email,
      insurance_adjuster_contact_info_phone,
      insurance_adjuster_name,
      insurance_carrier,
      insurance_claim_number,
      party_type,
      // rideshare_platform,
      saving,
    } = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const accidentPartyBody = await updateAccidentPartyPreparation({
        accident,
        insurance_adjuster_contact_info_email,
        insurance_adjuster_contact_info_phone,
        insurance_adjuster_name,
        insurance_carrier,
        insurance_claim_number,
        party_type,
        // rideshare_platform,
      });
      const updatedAccidentParty = await updateAccidentPartyApi(
        accidentParty.id,
        accidentPartyBody
      );
      onSaved(updatedAccidentParty);
      this.setState({saving: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {accident, accidentParty} = this.props;
    const {
      insurance_adjuster_contact_info_email,
      insurance_adjuster_contact_info_phone,
      insurance_adjuster_name,
      insurance_carrier,
      insurance_claim_number,
      party_type,
      // rideshare_platform,
      saving,
    } = this.state;
    return (
      <AccidentPartyEditor
        insurance_adjuster_contact_info_email={
          insurance_adjuster_contact_info_email
        }
        insurance_adjuster_contact_info_phone={
          insurance_adjuster_contact_info_phone
        }
        insurance_adjuster_name={insurance_adjuster_name}
        insurance_carrier={insurance_carrier}
        insurance_claim_number={insurance_claim_number}
        onChange={this.change}
        party_type={party_type}
        rideshare_platform={
          isAccidentPartyOfTypeRideshare(accidentParty.party_type)
            ? accident?.rideshare_signed_in_service || ''
            : null
        }
        saving={saving}
      />
    );
  }
}

export default subscriptionHOC(EditAccidentPartyContainer);
