// accident api
import listManualAccidentStates from '../../api/listManualStates.api.accident';

// driver redux actions
import {set as setAction} from '../../redux/actions';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class AccidentManualStateLoaderContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    loadingManualStates: PropTypes.bool,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadManualAccidentStates();
  }

  loadManualAccidentStates = async () => {
    const {dispatch} = this.props;

    this.setState({loading: true});
    dispatch(setAction({loadingManualStates: true}));

    try {
      const {manual_state_choices: manualStates} =
        await listManualAccidentStates();
      dispatch(setAction({loadingManualStates: false, manualStates}));
      this.setState({loading: false});
    } catch (error) {
      dispatch(setAction({loadingManualStates: false}));
      this.setState({loading: false});
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  loadingManualStates: state.accident.loadingManualStates,
}))(AccidentManualStateLoaderContainer);
