// components
import Select from '@matthahn/sally-ui/lib/components/Select/Select';

// local components
import Container from './components/Container';
import Label from './components/Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ItemListHeaderFilter = ({filterSize, label, type, ...props}) =>
  type === 'select' ? (
    <Container filterSize={filterSize}>
      <Label>{label}</Label>
      <Select size="large" notFilterable flat hideOverflow {...props} />
    </Container>
  ) : null;

ItemListHeaderFilter.propTypes = {
  filterSize: PropTypes.string,
  label: PropTypes.node,
  type: PropTypes.oneOf(['select']),
};

export default ItemListHeaderFilter;
