// accident components
import AccidentValueTable from '../../../accident/components/AccidentValueTable/AccidentValueTable';

// components
import {Card} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// libs
import getTimeZoneFromBranch from '../../../lib/getTimeZoneFromBranch';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const LiabilityFinancials = ({
  accident,
  lf_billed_amount,
  lf_description,
  lf_invoice_date,
  lf_type,
  onChange,
  onChangeSelection,
  onSave,
  saving,
}) => (
  <Card
    title="Liability Financials"
    buttonsRight={[
      {label: 'Save', loading: saving, onClick: onSave, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Content padding="none" noBorder>
        <AccidentValueTable
          label={lf_invoice_date.label.default}
          display={
            <AttributeInput
              value={lf_invoice_date}
              onChange={onChange}
              disabled={saving}
              timeZone={getTimeZoneFromBranch({
                branch: accident?.sally_branch,
              })}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={lf_type.label.default}
          display={
            <AttributeInput
              value={lf_type}
              onChange={onChange}
              disabled={saving}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        {/* <AccidentValueTable
          label={lf_type.label.default}
          display={
            <Select
              value={lf_type.api.format()}
              onChange={(value) => onChangeSelection(value, lf_type.attribute)}
              options={lf_type.additional.options.map((option) => ({
                value: option,
                label: option,
              }))}
              disabled={saving}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        /> */}
        <AccidentValueTable
          label={lf_billed_amount.label.default}
          display={
            <AttributeInput
              value={lf_billed_amount}
              onChange={onChange}
              disabled={saving}
              preValue="$"
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={lf_description.label.default}
          display={
            <AttributeInput
              value={lf_description}
              onChange={onChange}
              disabled={saving}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
      </Content>
    )}
  </Card>
);

LiabilityFinancials.propTypes = {
  accident: PropTypes.object,
  lf_billed_amount: PropTypes.object,
  lf_description: PropTypes.object,
  lf_invoice_date: PropTypes.object,
  lf_type: PropTypes.object,
  onChange: PropTypes.func,
  onChangeSelection: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
};

export default LiabilityFinancials;
