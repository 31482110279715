// attribute lib
import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

export default async ({accident, ...attributes}) => {
  const {rideshare_platform, ...accidentParty} = await prepare(attributes, {
    field: 'api',
    required: [
      'insurance_adjuster_contact_info_email',
      'insurance_adjuster_contact_info_phone',
      'insurance_adjuster_name',
      'insurance_carrier',
      'insurance_claim_number',
      'party_type',
    ],
  });
  return accidentParty;
};
