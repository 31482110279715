// accident events
import showDemandLetterGeneratorEvent from '../../../accident/events/showDemandLetterGenerator.event.accident';

// accident redux actions
import {updateAccident as updateAccidentAct} from '../../../accident/redux/actions';

// accidentClaim api
import updateAccidentClaimApi from '../../api/update.api.accidentClaim';

// accidentClaim attributes
import rfCheckRcvdAttribute from '../../attributes/rf_check_rcvd.attribute.accidentClaim';
import rfDateClosedAttribute from '../../attributes/rf_date_closed.attribute.accidentClaim';
import rfDemandAttribute from '../../attributes/rf_demand.attribute.accidentClaim';
import rfEstimateAttribute from '../../attributes/rf_estimate.attribute.accidentClaim';
import rfRecoveryAttribute from '../../attributes/rf_liability.attribute.accidentClaim';
import rfLossOfUseAttribute from '../../attributes/rf_loss_of_use.attribute.accidentClaim';
import rfSettledAttribute from '../../attributes/rf_settled.attribute.accidentClaim';

// accidentClaim components
import RecoveryFinancials from '../../components/RecoveryFinancials/RecoveryFinancials';

// accidentClaim containers
import FaultEditorContainer from '../FaultEditorContainer/FaultEditorContainer';

// accidentClaim events
import showFaultEditorEvent from '../../events/showFaultEditor.event.accidentClaim';

// accidentClaim preparations
import updateRecoveryFinancialsPreparation from '../../preparations/updateRecoveryFinancials.preparation.accidentClaim';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// react redux
import {connect} from 'react-redux';

class RecoveryFinancialsContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    rf_check_rcvd: rfCheckRcvdAttribute(''),
    rf_date_closed: rfDateClosedAttribute(''),
    rf_demand: rfDemandAttribute(''),
    rf_estimate: rfEstimateAttribute(''),
    rf_liability: rfRecoveryAttribute(''),
    rf_loss_of_use: rfLossOfUseAttribute(''),
    rf_settled: rfSettledAttribute(''),
    saving: false,
  };

  componentDidMount() {
    this.mounted = true;
    const {accident} = this.props;
    this.setState({
      rf_check_rcvd: rfCheckRcvdAttribute(
        accident?.dolclaim?.rf_check_rcvd || ''
      ),
      rf_date_closed: rfDateClosedAttribute(
        accident?.dolclaim?.rf_date_closed || ''
      ),
      rf_demand: rfDemandAttribute(accident?.dolclaim?.rf_demand || ''),
      rf_estimate: rfEstimateAttribute(accident?.dolclaim?.rf_estimate || ''),
      rf_liability: rfRecoveryAttribute(accident?.dolclaim?.rf_liability || ''),
      rf_loss_of_use: rfLossOfUseAttribute(
        accident?.dolclaim?.rf_loss_of_use || ''
      ),
      rf_settled: rfSettledAttribute(accident?.dolclaim?.rf_settled || ''),
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {accident, dispatch} = this.props;
    const {
      rf_check_rcvd,
      rf_date_closed,
      rf_demand,
      rf_estimate,
      rf_liability,
      rf_loss_of_use,
      rf_settled,
      saving,
    } = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const accidentClaimBody = await updateRecoveryFinancialsPreparation({
        rf_check_rcvd,
        rf_date_closed,
        rf_demand,
        rf_estimate,
        rf_liability,
        rf_loss_of_use,
        rf_settled,
      });
      const updatedAccidentClaim = await updateAccidentClaimApi({
        accidentId: accident.id,
        claim: accidentClaimBody,
      });
      if (!this.mounted) return;
      const updatedAccident = {
        ...accident,
        dolclaim: updatedAccidentClaim,
      };
      dispatch(updateAccidentAct(updatedAccident));
      this.setState({saving: false});
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  openFileBundle = () => {
    const {accident} = this.props;
    showDemandLetterGeneratorEvent.publish({accident});
  };

  openClaim = () => {
    const {accident} = this.props;
    showFaultEditorEvent.publish({accident});
  };

  render() {
    const {accident} = this.props;
    const {
      rf_check_rcvd,
      rf_date_closed,
      rf_demand,
      rf_estimate,
      rf_liability,
      rf_loss_of_use,
      rf_settled,
      saving,
    } = this.state;
    return (
      <Fragment>
        <RecoveryFinancials
          accident={accident}
          onChange={this.change}
          onClaim={this.openClaim}
          onFileBundle={this.openFileBundle}
          onSave={this.save}
          rf_check_rcvd={rf_check_rcvd}
          rf_date_closed={rf_date_closed}
          rf_demand={rf_demand}
          rf_estimate={rf_estimate}
          rf_liability={rf_liability}
          rf_loss_of_use={rf_loss_of_use}
          rf_settled={rf_settled}
          saving={saving}
        />
        <FaultEditorContainer />
      </Fragment>
    );
  }
}

export default connect()(RecoveryFinancialsContainer);
