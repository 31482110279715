// accident redux actions
import {updateAccident as updateAccidentAct} from '../../../accident/redux/actions';

// accidentClaim api
import updateAccidentClaimApi from '../../api/update.api.accidentClaim';

// accidentClaim attributes
import faultDistributionAttribute from '../../attributes/fault_distribution.attribute.accidentClaim';
import faultReasonAttribute from '../../attributes/fault_reason.attribute.accidentClaim';

// accidentClaim components
import FaultEditorModal from '../../components/FaultEditorModal/FaultEditorModal';

// accidentClaim events
import showFaultEditorEvent from '../../events/showFaultEditor.event.accidentClaim';

// accidentClaim preparations
import updateFaultPreparation from '../../preparations/updateFault.preparation.accidentClaim';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class FaultEditorContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  state = {
    accident: null,
    fault_distribution: faultDistributionAttribute(''),
    fault_reason: faultReasonAttribute(''),
    saving: false,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showFaultEditorEvent.subscribe(this.show));
  }

  show = ({accident}) => {
    this.setState({
      accident,
      fault_distribution: faultDistributionAttribute(
        accident?.dolclaim?.fault_distribution || ''
      ),
      fault_reason: faultReasonAttribute(
        accident?.dolclaim?.fault_reason || ''
      ),
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {dispatch} = this.props;
    const {accident, fault_distribution, fault_reason, saving} = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const accidentClaimBody = await updateFaultPreparation({
        fault_distribution,
        fault_reason,
      });
      const updatedAccidentClaim = await updateAccidentClaimApi({
        accidentId: accident.id,
        claim: accidentClaimBody,
      });
      const updatedAccident = {
        ...accident,
        dolclaim: updatedAccidentClaim,
      };
      dispatch(updateAccidentAct(updatedAccident));
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {fault_distribution, fault_reason, saving, visible} = this.state;
    return (
      <FaultEditorModal
        fault_distribution={fault_distribution}
        fault_reason={fault_reason}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        saving={saving}
        visible={visible}
      />
    );
  }
}

export default connect()(subscriptionHOC(FaultEditorContainer));
