// colors
import {red} from '@matthahn/sally-ui/lib/libs/colors';

// date lib
import isDateInPast from '../../lib/isInPast.lib.date';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const HighlightInPastDate = ({
  children,
  dateToCompare,
  highlightColor = red,
  referenceDate = new Date(),
}) => {
  const inPast = isDateInPast({dateToCompare, referenceDate});
  return (
    <Container color={inPast ? highlightColor : 'inherit'}>
      {children}
    </Container>
  );
};

HighlightInPastDate.propTypes = {
  children: PropTypes.node,
  dateToCompare: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  highlightColor: PropTypes.string,
  referenceDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
};

export default HighlightInPastDate;
