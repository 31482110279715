// accident components
import AccidentValueTable from '../../../accident/components/AccidentValueTable/AccidentValueTable';

// components
import {Button, Card} from '@matthahn/sally-ui';

// date lib
import convertDateTimeToDate from '../../../date/lib/convertDateTimeToDate.lib.date';
import convertDateToDateTime from '../../../date/lib/convertDateToDateTime.lib.date';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// local components
import ActionsContainer from './components/ActionsContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const RecoveryFinancials = ({
  accident,
  onChange,
  onClaim,
  onFileBundle,
  onSave,
  rf_check_rcvd,
  rf_date_closed,
  rf_demand,
  rf_estimate,
  rf_liability,
  rf_loss_of_use,
  rf_settled,
  saving,
}) => (
  <Card
    title="Recovery Financials"
    buttonsRight={[
      {label: 'Save', loading: saving, onClick: onSave, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Content padding="none" noBorder>
        <AccidentValueTable
          label="Actions"
          display={
            <ActionsContainer>
              <Button size="tiny" theme="grey" onClick={onClaim}>
                Claim Info
              </Button>
              <Button size="tiny" theme="grey" onClick={onFileBundle}>
                Generate Demand Letter
              </Button>
            </ActionsContainer>
          }
        />
        <AccidentValueTable
          label={rf_estimate.label.default}
          display={
            <AttributeInput
              value={rf_estimate}
              onChange={onChange}
              disabled={saving}
              size="small"
              preValue="$"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={rf_loss_of_use.label.default}
          display={
            <AttributeInput
              value={rf_loss_of_use}
              onChange={onChange}
              disabled={saving}
              preValue="$"
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={rf_demand.label.default}
          display={
            <AttributeInput
              value={rf_demand}
              onChange={onChange}
              disabled={saving}
              preValue="$"
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={rf_liability.label.default}
          display={
            <AttributeInput
              value={rf_liability}
              onChange={onChange}
              disabled={saving}
              preValue="%"
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={rf_settled.label.default}
          display={
            <AttributeInput
              value={rf_settled}
              onChange={onChange}
              disabled={saving}
              preValue="$"
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={rf_check_rcvd.label.default}
          display={
            <AttributeInput
              value={rf_check_rcvd}
              onChange={onChange}
              disabled={saving}
              size="small"
              flat
            />
          }
        />
        <AccidentValueTable
          label={rf_date_closed.label.default}
          display={
            <AttributeInput
              value={convertDateTimeToDate(rf_date_closed)}
              onChange={(value, ...attributes) =>
                onChange(convertDateToDateTime(value), ...attributes)
              }
              disabled={saving}
              // timeZone={getTimeZoneFromBranch({
              //   branch: accident?.sally_branch,
              // })}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
      </Content>
    )}
  </Card>
);

RecoveryFinancials.propTypes = {
  accident: PropTypes.object,
  onChange: PropTypes.func,
  onClaim: PropTypes.func,
  onFileBundle: PropTypes.func,
  onSave: PropTypes.func,
  rf_check_rcvd: PropTypes.object,
  rf_date_closed: PropTypes.object,
  rf_demand: PropTypes.object,
  rf_estimate: PropTypes.object,
  rf_liability: PropTypes.object,
  rf_loss_of_use: PropTypes.object,
  rf_settled: PropTypes.object,
  saving: PropTypes.bool,
};

export default RecoveryFinancials;
