import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'lf_type',
  label: {
    default: 'Type',
    short: 'Type',
  },
  options: ['PIP', 'PD&BI', 'PD ONLY', 'SUBRO', 'BI', 'UM/UIM'],
});
