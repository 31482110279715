import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import string from '@matthahn/sally-fw/lib/type/types/string.type';
import capitalWord from '@matthahn/sally-fw/lib/type/types/capitalWord.type';

export default attribute({
  type: TEXT,
  attribute: 'insurance_carrier',
  display: string,
  input: capitalWord,
  api: string,
  label: {
    default: 'Carrier',
    short: 'Carrier',
  },
  options: ['Hereford', 'The Hartford', 'Crum and Forster', 'CHUBB'],
});
