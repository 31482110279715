// components
import Heading from '@matthahn/sally-ui/lib/components/Heading/Heading';
import Table from '@matthahn/sally-ui/lib/components/Table/Table';

// local components
import HeadRow from './components/HeadRow';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const AccidentsCustomViewTable = ({
  accidents,
  columns,
  loading,
  onAccident,
  onPage,
  onSort,
  page,
  pages,
  sort,
  title,
}) => (
  <Fragment>
    <HeadRow>
      <Heading size="2">{title}</Heading>
    </HeadRow>
    <Table
      columns={columns}
      infinite={false}
      loading={loading}
      onPage={onPage}
      onSort={onSort}
      page={page}
      pages={pages}
      sort={sort}
      theme="orange"
    >
      {(TableRow) =>
        accidents.map((accident) => (
          <TableRow onClick={onAccident(accident)}>
            {(TableCol) =>
              [...columns].map(({key, label, render, ...columnProps}) => (
                <TableCol key={key} {...columnProps}>
                  {render({accident, key, label})}
                </TableCol>
              ))
            }
          </TableRow>
        ))
      }
    </Table>
  </Fragment>
);

AccidentsCustomViewTable.propTypes = {
  accidents: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  onAccident: PropTypes.func,
  onPage: PropTypes.func,
  onSort: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  sort: PropTypes.object,
  title: PropTypes.string,
};

export default AccidentsCustomViewTable;
