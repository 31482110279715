// accident redux actions
import {updateAccident as updateAccidentAct} from '../../../accident/redux/actions';

// accidentClaim api
import updateAccidentClaimApi from '../../api/update.api.accidentClaim';

// accidentClaim attributes
import lfBilledAmountAttribute from '../../attributes/lf_billed_amount.attribute.accidentClaim';
import lfDescriptionAttribute from '../../attributes/lf_description.attribute.accidentClaim';
import lfInvoiceDateAttribute from '../../attributes/lf_invoice_date.attribute.accidentClaim';
import lfTypeAttribute from '../../attributes/lf_type.attribute.accidentClaim';

// accidentClaim components
import LiabilityFinancials from '../../components/LiabilityFinancials/LiabilityFinancials';

// accidentClaim preparations
import updateLiabilityFinancialsPreparation from '../../preparations/updateLiabilityFinancials.preparation.accidentClaim';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class LiabilityFinancialsContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
  };

  state = {
    lf_billed_amount: lfBilledAmountAttribute(''),
    lf_description: lfDescriptionAttribute(''),
    lf_invoice_date: lfInvoiceDateAttribute(''),
    lf_type: lfTypeAttribute(''),
    saving: false,
  };

  componentDidMount() {
    this.mounted = true;
    const {accident} = this.props;
    this.setState({
      lf_billed_amount: lfBilledAmountAttribute(
        accident?.dolclaim?.lf_billed_amount || ''
      ),
      lf_description: lfDescriptionAttribute(
        accident?.dolclaim?.lf_description || ''
      ),
      lf_invoice_date: lfInvoiceDateAttribute(
        accident?.dolclaim?.lf_invoice_date || ''
      ),
      lf_type: lfTypeAttribute(accident?.dolclaim?.lf_type || ''),
      // lf_type: lfTypeAttribute((accident?.dolclaim?.lf_type || '').split(',')),
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  changeSelection = (value, key) => {
    if (this.state.saving) return;
    const previousValues = this.state?.[key]?.api?.format?.() || [];
    const updatedValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    const updatedAttribute = this.state?.[key]?.reinit(updatedValues);
    this.setState({[key]: updatedAttribute});
  };

  save = async () => {
    const {accident, dispatch} = this.props;
    const {lf_billed_amount, lf_description, lf_invoice_date, lf_type, saving} =
      this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const accidentClaimBody = await updateLiabilityFinancialsPreparation({
        lf_billed_amount,
        lf_description,
        lf_invoice_date,
        lf_type,
      });
      const updatedAccidentClaim = await updateAccidentClaimApi({
        accidentId: accident.id,
        claim: accidentClaimBody,
      });
      if (!this.mounted) return;
      const updatedAccident = {
        ...accident,
        dolclaim: updatedAccidentClaim,
      };
      dispatch(updateAccidentAct(updatedAccident));
      this.setState({saving: false});
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {accident} = this.props;
    const {lf_billed_amount, lf_description, lf_invoice_date, lf_type, saving} =
      this.state;
    return (
      <LiabilityFinancials
        accident={accident}
        lf_billed_amount={lf_billed_amount}
        lf_description={lf_description}
        lf_invoice_date={lf_invoice_date}
        lf_type={lf_type}
        onChange={this.change}
        onChangeSelection={this.changeSelection}
        onSave={this.save}
        saving={saving}
      />
    );
  }
}

export default connect()(LiabilityFinancialsContainer);
