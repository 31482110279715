import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default ({accidentId, claim = null}) =>
  f3tch(`/accidents/dol/${accidentId}/update_claim/`)
    .profile(profile())
    .body(claim)
    .patch();
