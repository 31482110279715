import React from 'react';
import PropTypes from 'prop-types';

// accident components
import AccidentDashboardIndicator from '../AccidentDashboardIndicator/AccidentDashboardIndicator';

// accident containers
import AccidentClaimContainer from '../../containers/AccidentClaimContainer/AccidentClaimContainer';
import AccidentDriverStatementContainer from '../../containers/AccidentDriverStatementContainer/AccidentDriverStatementContainer';
import AccidentRepairContainer from '../../containers/AccidentRepairContainer/AccidentRepairContainer';
import PhotosStateContainer from '../../containers/PhotosStateContainer/PhotosStateContainer';
// import PoliceReportStateContainer from '../../containers/PoliceReportStateContainer/PoliceReportStateContainer';
import ReceiptStateContainer from '../../containers/ReceiptStateContainer/ReceiptStateContainer';

// components
import {Card, Table} from '@matthahn/sally-ui';

// local data
import columns from './data/columns';

const AccidentDashboard = ({accident, onDriver}) => (
  <Card
    title="Dashboard"
    icon="dashboard"
    headerActions={[
      {icon: 'user', tooltip: 'Go to driver page', onClick: onDriver},
    ]}
  >
    {(Content) => (
      <Content padding="none">
        <Table
          columns={columns(accident)}
          infinite={false}
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) => [
            <TableRow key="row">
              {(TableColumn) =>
                [
                  <TableColumn key="driverStatement">
                    <AccidentDriverStatementContainer />
                  </TableColumn>,
                  <TableColumn key="photos">
                    <PhotosStateContainer accident={accident}>
                      {({state}) => <AccidentDashboardIndicator {...state} />}
                    </PhotosStateContainer>
                  </TableColumn>,
                  <TableColumn key="charge">
                    <AccidentRepairContainer />
                  </TableColumn>,
                  accident.tow_required && (
                    <TableColumn key="receipts">
                      <ReceiptStateContainer accident={accident}>
                        {({state}) => <AccidentDashboardIndicator {...state} />}
                      </ReceiptStateContainer>
                    </TableColumn>
                  ),
                  <TableColumn key="claim">
                    <AccidentClaimContainer />
                  </TableColumn>,
                ].filter((column) => !!column)
              }
            </TableRow>,
          ]}
        </Table>
      </Content>
    )}
  </Card>
);

AccidentDashboard.propTypes = {
  accident: PropTypes.object,
  onDriver: PropTypes.func,
};

export default AccidentDashboard;
