// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// textarea
import TextareaAutosize from 'react-textarea-autosize';

const TextArea = ({value, onChange, textAreaProps = {}, styleProps = {}}) => (
  <TextareaAutosize
    minRows={1}
    value={value}
    size="large"
    onChange={(e) => onChange(e.target.value)}
    style={{
      width: '100%',
      height: '100%',
      display: 'block',
      flex: 1,
      padding: '6px',
      margin: '0px',
      background: 'none',
      border: `2px solid ${darkGrey}`,
      borderRadius: '3px',
      outline: 'none',
      fontFamily: 'ProximaNova',
      fontSize: 'inherit',
      color: 'black',
      resize: 'none',
      ...styleProps,
    }}
    {...textAreaProps}
  />
);

TextArea.propTypes = {
  onChange: PropTypes.func,
  styleProps: PropTypes.object,
  textAreaProps: PropTypes.object,
  value: PropTypes.any,
};

export default TextArea;
