const columns = [
  {
    key: 'assignee',
    label: 'Assignee',
  },
  {
    key: 'followup_datetime',
    label: 'Followup Date',
  },
  {
    key: 'state',
    label: 'State',
  },
];

export default columns;
