import f3tch from 'f3tch';

import profile from '../../api/profiles/json.profile.api';

export default (ids = [], {svid = null, driver_name = null} = {}) => {
  const data = {ids};
  if (!!svid) data.svid = svid;
  if (!!driver_name) data.driver_name = driver_name;
  return f3tch('/documents/document_zip/').profile(profile()).body(data).post();
};
