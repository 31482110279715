import {fileName, folder, date} from '../../../document';

// Attributes
import {plate as plateAttr} from '../../../vehicle/attributes';

// Errors
import plateMissmatch from '../../../vehicle/errors/plateMissmatch.error.vehicle';

export default folder({
  attributes: [plateAttr],
  fileAttributes: ['plate'],
  optionalAttributes: ['plate'],
  defaultFileName: 'Driver Statement',
  // DRIVER STATEMENT - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `DRIVER STATEMENT - ${accident.driver?.first_name}_${
        accident.driver?.last_name
      } - ${accident?.vehicle?.svid} - ${date()}`
  ),
  preSaveValidation: ({plate, accident}) => {
    if (
      !!accident?.vehicle?.plate &&
      accident?.vehicle?.plate !== plate.api.format()
    )
      throw plateMissmatch({
        message: 'Plates need to match',
      });
  },
  folderName: 'Driver Statement',
  type: 'accidentDriverStatement',
});
