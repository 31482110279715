// accidentParty lib
// import isAccidentPartyOfTypeRideshare from '../lib/isRideshareType.lib.accidentParty';

// attribute lib
import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

export default async ({accident, ...attributes}) => {
  const {rideshare_platform, ...accidentParty} = await prepare(attributes, {
    field: 'api',
    required: [
      'insurance_adjuster_contact_info_email',
      'insurance_adjuster_contact_info_phone',
      'insurance_adjuster_name',
      'insurance_carrier',
      'insurance_claim_number',
      'party_type',
    ],
  });
  // if (isAccidentPartyOfTypeRideshare(accidentParty.party_type)) {
  //   await prepare(attributes, {
  //     field: 'api',
  //     required: ['rideshare_platform'],
  //   });
  //   accidentParty.rideshare_platform = rideshare_platform;
  // }
  accidentParty.dol_claim = accident?.dolclaim?.id;
  return accidentParty;
};
