import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Alerts
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Attributes
import * as partyInvolvedAttributes from '../../../accidentPartyInvolved/attributes';

// Api
import createPartyApi from '../../../accidentPartyInvolved/api/create.api.accidentPartyInvolved';
import deletePartyApi from '../../../accidentPartyInvolved/api/delete.api.accidentPartyInvolved';
// import listPartiesApi from '../../../accidentPartyInvolved/api/list.api.accidentPartyInvolved';
import updatePartyApi from '../../../accidentPartyInvolved/api/update.api.accidentPartyInvolved';

// Components
import PartiesInvolvedCard from '../../components/PartiesInvolvedCard/PartiesInvolvedCard';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Input Types
import SWITCH from '@matthahn/sally-fw/lib/inputTypes/switch.inputType';
import TOGGLE from '@matthahn/sally-fw/lib/inputTypes/toggle.inputType';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// Preparations
import createPartyPrep from '../../../accidentPartyInvolved/preparation/create.preparation.accidentPartyInvolved';
import updatePartyPrep from '../../../accidentPartyInvolved/preparation/update.preparation.accidentPartyInvolved';

// Helpers
const convertToAttributes = (partyInvolved = null) =>
  Object.entries(partyInvolvedAttributes).reduce((combined, [key, current]) => {
    const defaultValue = [SWITCH, TOGGLE].includes(current.type) ? false : '';
    return {
      ...combined,
      [current.attribute]: current(
        !!partyInvolved
          ? partyInvolved[current.attribute] || defaultValue
          : defaultValue
      ),
    };
  }, {});

class PartiesInvolvedContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
  };

  state = {
    deletingPartyId: null,
    loading: true,
    partiesInvolved: [],
    partyInvolved: null,
    saving: false,
    ...convertToAttributes(),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {accident} = this.props;
    this.setState({loading: true});
    try {
      // const {results} = await listPartiesApi({accident: accident.id});
      const partyInvolved = [...accident.parties_involved];
      this.setState({
        loading: false,
        partyInvolved: partyInvolved || null,
        partiesInvolved: [...accident.parties_involved].map((party) => ({
          ...party,
          attributes: convertToAttributes(party),
        })),
        ...convertToAttributes(partyInvolved),
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  change = (partyId) => (value, key) => {
    const {loading, partiesInvolved, saving} = this.state;
    if (loading || saving) return;
    const updatedPartiesInvolved = [...partiesInvolved].map((party) =>
      party.id === partyId
        ? {...party, attributes: {...party.attributes, [key]: value}}
        : party
    );
    this.setState({partiesInvolved: updatedPartiesInvolved});
  };

  onChange = (value, key) => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    this.setState({[key]: value});
  };

  prepareForDeleting = (partyInvolvedId) => () => {
    const {deletingPartyId, loading, saving} = this.state;
    if (!!deletingPartyId || loading || saving) return;
    notify({
      id: 'delete',
      title: 'Confirm',
      icon: undefined,
      content: 'Are you sure you want to delete this Party?',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Delete',
        onClick: () => this.deleteParty(partyInvolvedId),
      },
    });
  };

  deleteParty = async (partyId) => {
    const {accident} = this.props;
    const {deletingPartyId, loading, partiesInvolved, saving} = this.state;

    if (!!deletingPartyId || loading || saving) return;

    this.setState({deletingPartyId: partyId});

    try {
      await deletePartyApi(accident.id, partyId);
      if (!this.mounted) return;
      const updatedPartiesInvolved = [...partiesInvolved].filter(
        ({id}) => id !== partyId
      );
      this.setState({
        deletingPartyId: null,
        partiesInvolved: updatedPartiesInvolved,
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({deletingPartyId: null});
    }
  };

  prepareParty = async ({accident, partyInvolved}) => {
    const prep =
      partyInvolved.id === 'create' ? createPartyPrep : updatePartyPrep;
    const data = await prep({
      ...partyInvolved.attributes,
      dol: partyInvolvedAttributes.dol(accident.id),
    });
    return {id: partyInvolved.id, ...data};
  };

  saveParty = async ({partyInvolved}) => {
    const {id: partyId, ...party} = partyInvolved;
    const api = (apiProps) =>
      partyId !== 'create'
        ? updatePartyApi(partyInvolved.dol, partyId, apiProps)
        : createPartyApi(partyInvolved.dol, apiProps);
    const updatedPartyInvolved = await api(party);
    return updatedPartyInvolved;
  };

  saveParties = async () => {
    const {accident} = this.props;
    const {loading, saving, partiesInvolved} = this.state;

    if (loading || saving) return;
    this.setState({saving: true});

    try {
      const preparedParties = await Promise.all(
        [...partiesInvolved].map((partyInvolved) =>
          this.prepareParty({accident, partyInvolved})
        )
      );
      const updatedPartiesInvolved = await Promise.all(
        [...preparedParties].map((partyInvolved) =>
          this.saveParty({partyInvolved})
        )
      );
      const parsedPartiesInvolved = [...updatedPartiesInvolved].map(
        (party) => ({
          ...party,
          attributes: convertToAttributes(party),
        })
      );
      this.setState({saving: false, partiesInvolved: parsedPartiesInvolved});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({saving: false});
    }
  };

  save = async () => {
    const {accident} = this.props;
    const {
      loading,
      saving,
      partyInvolved,
      dol,
      partiesInvolved,
      ...attributes
    } = this.state;
    if (loading || saving) return;

    const api = (apiProps) =>
      !!partyInvolved
        ? updatePartyApi(accident.id, partyInvolved.id, apiProps)
        : createPartyApi(accident.id, apiProps);
    const prep = !!partyInvolved ? updatePartyPrep : createPartyPrep;

    this.setState({saving: true});

    try {
      const preparedData = await prep({
        ...attributes,
        dol: partyInvolvedAttributes.dol(accident.id),
      });
      const newPartyInvolved = await api(preparedData);
      if (!this.mounted) return;
      this.setState({saving: false, partyInvolved: newPartyInvolved});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({saving: false});
    }
  };

  isAdding = () =>
    [...this.state.partiesInvolved].find(({id}) => id === 'create');

  addNewParty = () => {
    const {loading, saving, partiesInvolved} = this.state;
    if (loading || saving) return;
    const newParty = {
      id: 'create',
      attributes: convertToAttributes(),
    };
    this.setState({partiesInvolved: [newParty, ...partiesInvolved]});
  };

  removeNewParty = () => {
    const {loading, saving, partiesInvolved} = this.state;
    if (loading || saving) return;
    const updatedParties = [...partiesInvolved].filter(
      ({id}) => id !== 'create'
    );
    this.setState({partiesInvolved: updatedParties});
  };

  render() {
    const {deletingPartyId, saving, partiesInvolved} = this.state;
    return (
      <PartiesInvolvedCard
        deletingPartyId={deletingPartyId}
        isAdding={this.isAdding()}
        onCancelAdding={this.removeNewParty}
        onChange={this.change}
        onDelete={this.prepareForDeleting}
        onNewParty={this.addNewParty}
        onSave={this.saveParties}
        partiesInvolved={partiesInvolved}
        saving={!!deletingPartyId || saving}
      />
    );
  }
}

export default PartiesInvolvedContainer;
