// attribute lib
import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

export default async (attributes) => {
  const accidentClaim = await prepare(attributes, {
    field: 'api',
    required: [],
  });
  if (Array.isArray(accidentClaim?.lf_type)) {
    accidentClaim.lf_type = accidentClaim.lf_type.join(',');
  }
  return accidentClaim;
};
