// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';
import InputTyping from '@matthahn/sally-ui/lib/components/InputTyping/InputTyping';
import Table from '@matthahn/sally-ui/lib/components/Table/Table';

// layout components
import ItemListHeaderFilter from '../ItemListHeaderFilter/ItemListHeaderFilter';

// local components
import ActionContainer from './components/ActionContainer';
import Container from './components/Container';
import Content from './components/Content';
import Menu from './components/Menu';
import SearchContainer from './components/SearchContainer';
import TableHeader from './components/TableHeader';
import TableContent from './components/TableContent';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ItemListLayout = ({
  actions = [],
  children,
  columns,
  decoupled = false,
  filters = [],
  onSearch,
  onSort,
  renderTable = (dom) => dom,
  search,
  searchPlaceholder = 'Search',
  sort,
  tableProps = {},
}) => {
  const tableDom = (
    <Table
      columns={columns}
      infinite={false}
      noBorder={!decoupled}
      noRadius={!decoupled}
      headless={!decoupled}
      theme="orange"
      {...tableProps}
    >
      {children}
    </Table>
  );

  return (
    <Container>
      <Menu>
        {!!onSearch && (
          <SearchContainer>
            <InputTyping
              value={search}
              onChange={onSearch}
              preIcon="search1"
              placeholder={searchPlaceholder}
              size="large"
              treshold={400}
              flat
            />
          </SearchContainer>
        )}
        {filters.map(({key, filterSize, ...filterProps}) => (
          <ItemListHeaderFilter
            key={key}
            filterSize={filterSize}
            {...filterProps}
          />
        ))}
        {actions.map(({key, ...actionProps}) => (
          <ActionContainer key={key}>
            <Button flat {...actionProps} />
          </ActionContainer>
        ))}
      </Menu>
      {!decoupled && (
        <TableHeader>
          <Table
            columns={columns}
            sort={sort}
            onSort={onSort}
            noBorder
            noRadius
            noContent
          />
        </TableHeader>
      )}
      <Content decoupled={decoupled}>
        <TableContent decoupled={decoupled}>
          {renderTable(tableDom)}
        </TableContent>
      </Content>
    </Container>
  );
};

ItemListLayout.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
    })
  ),
  children: PropTypes.func,
  columns: PropTypes.array,
  decoupled: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      label: PropTypes.node,
      type: PropTypes.string,
    })
  ),
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  renderTable: PropTypes.func,
  search: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  sort: PropTypes.object,
  tableProps: PropTypes.object,
};

export default ItemListLayout;
