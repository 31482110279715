// date
import {parseISO, isAfter} from 'date-fns';

// samson lib
import sumItems from '../../samson/lib/sum.lib.item';

const getChargeAmount = ({tickets = [], accident}) => {
  const accidentDol = parseISO(accident.datetime_of_loss);
  const relevantTickets = [...tickets].filter((ticket) =>
    isAfter(parseISO(ticket.created_at), accidentDol)
  );
  const relevantItems = [...relevantTickets].map(({items}) => items).flat();
  return sumItems(relevantItems);
};

export default getChargeAmount;
