import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {grey, red},
} = lib;

const Container = styled.div`
  width: 100%;
  height: ${({full}) => (full ? '100vh' : 'calc(100vh - 60px)')};
  position: ${({full}) => (full ? 'fixed' : 'static')};
  z-index: 1000000;
  background: ${grey};
  top: 0px;
  left: 0px;
  font-size: 60px;
  color: ${red};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Container;
