const convertDateTimeToDate = (dateTime) => {
  const isAttribute = !!dateTime?.api;
  const dateTimeValue =
    (isAttribute ? dateTime?.api?.format?.() : dateTime) || '';
  const [date] = dateTimeValue.split('T');
  const dateValue = date || '';
  return isAttribute ? dateTime.reinit(dateValue) : dateValue;
};

export default convertDateTimeToDate;
