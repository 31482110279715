import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  position: fixed;
  top: 110px;
  left: 0px;
  background: rgba(255, 255, 255, 0.95);
  ${'' /* backdrop-filter: blur(2px); */}
  z-index: 10;
`;

export default Header;
