import React from 'react';
import PropTypes from 'prop-types';

// accident components

// accident containers
import AccidentStateManagerContainer from '../../containers/AccidentStateManagerContainer/AccidentStateManagerContainer';

// accidentClaim containers
import AssigneeContainer from '../../../accidentClaim/containers/AssigneeContainer/AssigneeContainer';
import FollowupDateContainer from '../../../accidentClaim/containers/FollowupDateContainer/FollowupDateContainer';

// components
import {Table} from '@matthahn/sally-ui';

// local data
import columns from './data/columns';

const AccidentClaimDashboard = ({accident, onDriver}) => (
  <Table columns={columns} infinite={false} noRadius smallNoResultsLabel>
    {(TableRow) => [
      <TableRow key="row">
        {(TableColumn) => [
          <TableColumn key="assignee">
            <AssigneeContainer accident={accident} />
          </TableColumn>,
          <TableColumn key="followup_datetime">
            <FollowupDateContainer accident={accident} />
          </TableColumn>,
          <TableColumn key="state">
            <AccidentStateManagerContainer />
          </TableColumn>,
        ]}
      </TableRow>,
    ]}
  </Table>
);

AccidentClaimDashboard.propTypes = {
  accident: PropTypes.object,
  onDriver: PropTypes.func,
};

export default AccidentClaimDashboard;
