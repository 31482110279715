// accident api
import listAccidentsApi from '../../api/list.api.accident';

// accident components
import AccidentsCustomViewTable from '../../components/AccidentsCustomViewTable/AccidentsCustomViewTable';

// api lib
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// accident routes
import accidentRoute from '../../pages/AccidentPage/route';

// alerts
import alert from '@matthahn/sally-ui/lib/libs/alert';

// libs
import numberOfPages from '../../../lib/numberOfPages';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react router
import {withRouter} from 'react-router-dom';

// uuid
import {v4} from 'uuid';

class AccidentsCustomViewContainer extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        render: PropTypes.func,
      })
    ),
    defaultSort: PropTypes.object,
    history: PropTypes.object,
    query: PropTypes.object,
    title: PropTypes.string,
  };

  static OPTIONS = {
    perPage: 20,
  };

  static DEFAULT_SORT = {
    key: 'created_at',
    direction: 'asc',
  };

  apiId = null;

  state = {
    accidents: [],
    loading: false,
    page: 1,
    results: 0,
    sort: {...this.constructor.DEFAULT_SORT},
  };

  componentDidMount() {
    const {defaultSort} = this.props;
    this.mounted = true;
    const sort = defaultSort || {...this.constructor.DEFAULT_SORT};
    this.getAccidents({sort});
  }

  componentDidUpdate(prevProps, prevState) {
    const didQueryAttributeLengthChange =
      Object.keys(prevProps.query).length !==
      Object.keys(this.props.query).length;
    const didAttributeValuesChange = Object.entries(this.props.query).some(
      ([key, value]) => prevProps.query[key] !== value
    );
    if (didQueryAttributeLengthChange || didAttributeValuesChange)
      this.getAccidents();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getAccidents = async ({
    page = this.state.page,
    sort = this.state.sort,
    branch = this.state.branch,
  } = {}) => {
    const apiId = v4();
    this.apiId = apiId;

    const {query} = this.props;
    const {perPage} = this.constructor.OPTIONS;

    this.setState({
      loading: true,
      accidents: [],
      page,
      sort,
      branch,
    });

    const actualQuery = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
      ...query,
    };

    try {
      const {results, count} = await listAccidentsApi(actualQuery);
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({accidents: results, loading: false, results: count});
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      alert.info(`Failed to fetch accidents: ${error}`);
      this.setState({loading: false, accidents: [], results: 0});
    }
  };

  onPage = (page) => this.getAccidents({page});

  onSort = (sort) => this.getAccidents({page: 1, sort});

  onAccident = (accident) => () => {
    this.props.history.push(accidentRoute(accident.id));
  };

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  render() {
    const {columns, title} = this.props;
    const {accidents, loading, page, sort} = this.state;
    return (
      <AccidentsCustomViewTable
        accidents={accidents}
        columns={columns}
        loading={loading}
        onAccident={this.onAccident}
        onPage={this.onPage}
        onSort={this.onSort}
        page={page}
        pages={this.pages()}
        sort={sort}
        title={title}
      />
    );
  }
}

export default withRouter(AccidentsCustomViewContainer);
