import React from 'react';
import PropTypes from 'prop-types';

// components
import {Button} from '@matthahn/sally-ui';

const DeleteAccident = ({loading, onClick}) => (
  <Button
    size="small"
    theme="grey"
    loading={loading}
    icon="delete"
    onClick={onClick}
  >
    Delete
  </Button>
);

DeleteAccident.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DeleteAccident;
