import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (accidentId, accidentParty) =>
  f3tch(`/accidents/dol/claim/${accidentId}/create_party/`)
    .profile(profile())
    .body(accidentParty)
    .post();
