import {DATE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import {date, apiDate} from '@matthahn/sally-fw/lib/type';

export default attribute({
  type: DATE,
  attribute: 'rf_date_closed',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Date Closed',
    short: 'Date Closed',
  },
});
