import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import ImageSliderCard from '../../../layout/components/ImageSliderCard/ImageSliderCard';

class AccidentPhotosCard extends Component {
  static propTypes = {
    branch: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    loading: PropTypes.bool,
    fullScreen: PropTypes.bool,
    images: PropTypes.array,
    toggleFullScreen: PropTypes.func,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    onSliderRef: PropTypes.func,
  };

  render() {
    const {images, branch} = this.props;
    return (
      <ImageSliderCard title="Scene photos" images={images} branch={branch} />
    );
  }
}

export default AccidentPhotosCard;
