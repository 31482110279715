// accident containers
import AccidentsCustomViewContainer from '../../containers/AccidentsCustomViewContainer/AccidentsCustomViewContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Container from '@matthahn/sally-ui/lib/components/Grid/Container/Container';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Tag from '@matthahn/sally-ui/lib/components/Tag/Tag';

// colors
import {orange} from '@matthahn/sally-ui/lib/libs/colors';

// Columns
import mainTableColumns from './data/mainTableColumns';

// date components
import HighlightInPastDate from '../../../date/components/HighlightInPastDate/HighlightInPastDate';

// date lib
import {subDays} from 'date-fns';

// driver lib
import getDriverName from '../../../driver/lib/getName.lib.driver';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// local components
import Overflow from './components/Overflow';

// Lib
import getAccidentState from '../../lib/getStateObject.lib.accident';
import sum from '@matthahn/sally-fw/lib/lib/sum';

// Types
import {amount, dateTime} from '@matthahn/sally-fw/lib/type';

// user lib
import getUsername from '../../../user/lib/getUsername.lib.user';

const AccidentsList = ({
  accidents,
  actions,
  claimsDiaryManualStates,
  filters,
  loading,
  onAccident,
  onPage,
  onSearch,
  onSort,
  page,
  pages,
  query,
  search,
  sort,
  users,
}) => (
  <ItemListLayout
    actions={actions}
    columns={mainTableColumns}
    filters={filters}
    decoupled
    onSearch={onSearch}
    onSort={onSort}
    renderTable={(dom) => (
      <Container>
        <Row margin>
          <Column>{dom}</Column>
        </Row>
        <Row>
          <Column size={1 / 2}>
            <AccidentsCustomViewContainer
              columns={[
                {
                  key: 'id',
                  label: 'ID',
                  span: 0.5,
                  sortable: true,
                  render: ({accident}) => accident.id,
                },
                {
                  key: 'created_at',
                  label: 'Notice Date',
                  sortable: true,
                  render: ({accident}) => (
                    <HighlightInPastDate
                      dateToCompare={accident.created_at}
                      referenceDate={subDays(new Date(), 3)}
                      highlightColor={orange}
                    >
                      <HighlightInPastDate
                        referenceDate={subDays(new Date(), 4)}
                        dateToCompare={accident.created_at}
                      >
                        {dateTime(accident.created_at).format()}
                      </HighlightInPastDate>
                    </HighlightInPastDate>
                  ),
                },
                {
                  key: 'datetime_of_loss',
                  label: 'Date of Loss',
                  sortable: true,
                  render: ({accident}) =>
                    dateTime(accident.datetime_of_loss).format(),
                },
                {
                  key: 'driver',
                  label: 'Driver',
                  render: ({accident}) => getDriverName(accident.driver) || '-',
                },
                {
                  key: 'vehicle',
                  label: 'Vehicle',
                  render: ({accident}) =>
                    !!accident.vehicle ? accident.vehicle.svid : '-',
                },
              ]}
              query={{...query, accidentstate__auto_state: 'pending_4x4'}}
              title="Pending 4x4s"
            />
          </Column>
          <Column size={1 / 2}>
            <AccidentsCustomViewContainer
              columns={[
                {
                  key: 'id',
                  label: 'ID',
                  span: 0.5,
                  sortable: true,
                  render: ({accident}) => accident.id,
                },
                {
                  key: 'created_at',
                  label: 'Notice Date',
                  sortable: true,
                  render: ({accident}) =>
                    dateTime(accident.created_at).format(),
                },
                {
                  key: 'dolclaim.followup_datetime',
                  label: 'Followup Date',
                  sortable: true,
                  render: ({accident}) =>
                    !!accident?.dolclaim?.followup_datetime ? (
                      <HighlightInPastDate
                        dateToCompare={accident?.dolclaim?.followup_datetime}
                      >
                        {dateTime(accident.dolclaim.followup_datetime).format()}
                      </HighlightInPastDate>
                    ) : (
                      '-'
                    ),
                },
                {
                  key: 'datetime_of_loss',
                  label: 'Date of Loss',
                  sortable: true,
                  render: ({accident}) =>
                    dateTime(accident.datetime_of_loss).format(),
                },
                {
                  key: 'driver',
                  label: 'Driver',
                  render: ({accident}) => (
                    <Overflow>{getDriverName(accident.driver) || '-'}</Overflow>
                  ),
                },
                {
                  key: 'vehicle',
                  label: 'Vehicle',
                  render: ({accident}) => (
                    <Overflow>
                      {!!accident.vehicle ? accident.vehicle.svid : '-'}
                    </Overflow>
                  ),
                },
                {
                  key: 'demandAmount',
                  label: 'Demand Amount',
                  render: ({accident}) =>
                    !!accident?.dolclaim?.rf_demand
                      ? `$${amount(accident?.dolclaim?.rf_demand).format()}`
                      : '-',
                },
                {
                  key: 'state',
                  label: 'State',
                  render: ({accident}) => (
                    <Tag theme={getAccidentState(accident).color}>
                      {getAccidentState(accident).label}
                    </Tag>
                  ),
                },
                {
                  key: 'assignee',
                  label: 'Assignee',
                  render: ({accident}) => (
                    <Overflow>
                      {getUsername({
                        userId: accident?.dolclaim?.assigned_user,
                        users,
                      })}
                    </Overflow>
                  ),
                },
              ]}
              query={{
                ...query,
                accidentstate__manual_state__in: [
                  ...claimsDiaryManualStates,
                ].join(','),
              }}
              defaultSort={{
                key: 'dolclaim.followup_datetime',
                direction: 'asc',
              }}
              title="Claims Diary"
            />
          </Column>
        </Row>
      </Container>
    )}
    search={search}
    searchPlaceholder="Search Accidents"
    sort={sort}
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      accidents.map((accident) => {
        const chargeAmounts = [...(accident.charges || [])].map(
          (charge) => charge.amount
        );
        const chargeSum = sum(...chargeAmounts);
        return (
          <TableRow onClick={onAccident(accident)}>
            {(TableCol) => [
              <TableCol key="id" span={0.5}>
                {accident.id}
              </TableCol>,
              <TableCol key="datetime_of_loss">
                {dateTime(accident.datetime_of_loss).format()}
              </TableCol>,
              <TableCol key="driver">
                {!!accident.driver
                  ? `${accident.driver.first_name} ${accident.driver.last_name}`
                  : '-'}
              </TableCol>,
              <TableCol key="vehicle">
                {!!accident.vehicle ? accident.vehicle.svid : '-'}
              </TableCol>,
              <TableCol key="insurance">
                {[
                  {
                    key: 'insurance_carrier',
                    label: 'Carrier',
                  },
                  {
                    key: 'insurance_claim_number',
                    label: 'Claim Number',
                  },
                ].map(({key, label}) => (
                  <div key={key}>
                    {label}: {accident?.[key] || '-'}
                  </div>
                ))}
              </TableCol>,
              <TableCol key="demandAmount">
                {!!accident?.dolclaim?.rf_demand
                  ? `$${amount(accident?.dolclaim?.rf_demand).format()}`
                  : '-'}
              </TableCol>,
              <TableCol key="amountReceived">
                {!!accident?.dolclaim?.rf_settled
                  ? `$${amount(accident?.dolclaim?.rf_settled).format()}`
                  : '-'}
              </TableCol>,
              <TableCol key="deductibleAmountCharged">
                {!!chargeSum ? `$${amount(chargeSum).format()}` : '-'}
              </TableCol>,
              <TableCol key="state">
                <Tag theme={getAccidentState(accident).color}>
                  {getAccidentState(accident).label}
                </Tag>
              </TableCol>,
              <TableCol key="assignee">
                {getUsername({
                  userId: accident?.dolclaim?.assigned_user,
                  users,
                })}
              </TableCol>,
            ]}
          </TableRow>
        );
      })
    }
  </ItemListLayout>
);

AccidentsList.propTypes = {
  accidents: PropTypes.array,
  actions: PropTypes.array,
  claimsDiaryManualStates: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  onAccident: PropTypes.func,
  onNewAccident: PropTypes.func,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  query: PropTypes.object,
  search: PropTypes.string,
  sort: PropTypes.object,
  users: PropTypes.array,
};

export default AccidentsList;
