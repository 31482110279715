import styled, {css} from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

// constants
const SIZES = {
  medium: '200px',
  large: '350px',
  extraLarge: '450px',
};

const Container = styled.div`
  ${({filterSize}) => {
    const size = SIZES[filterSize] || SIZES.medium;
    return css`
      width: ${size};
      max-width: ${size};
      min-width: ${size};
    `;
  }}
  border-left: 1px solid ${grey};
  position: relative;
  padding-left: 10px;
`;

export default Container;
