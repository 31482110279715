// accident api
import generateDemandLetterApi from '../../api/generateDemandLetter.api.accident';

// accident components
import GenerateDemandLetterModal from '../../components/GenerateDemandLetterModal/GenerateDemandLetterModal';

// accident events
import showDemandLetterGeneratorEvent from '../../events/showDemandLetterGenerator.event.accident';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import downloadFile from '../../../lib/downloadFile';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class GenerateDemandLetterContainer extends Component {
  static propTypes = {
    accidentParties: PropTypes.array,
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    accident: null,
    generating: false,
    visible: false,
    partyInvolvedId: null,
    claimPartyId: null,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showDemandLetterGeneratorEvent.subscribe(this.show));
  }

  show = ({accident}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      accident,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.generating) return;
    this.setState({visible: false});
  };

  change = (key) => (value) => {
    if (this.state.generating) return;
    this.setState({[key]: value});
  };

  generateDemandLetter = async () => {
    const {accident, generating, partyInvolvedId, claimPartyId} = this.state;
    if (generating) return;

    if (!partyInvolvedId || !claimPartyId) {
      alert.warning('Please select all required fields.');
      return;
    }
    this.setState({generating: true});

    try {
      const file = await generateDemandLetterApi({
        accidentId: accident.id,
        partyInvolvedId,
        claimPartyId,
      });
      downloadFile(file, `${accident.id} Demand Letter.pdf`);
      this.setState({generating: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({generating: false});
    }
  };

  claimParties = () => {
    const {accidentParties} = this.props;
    return [...accidentParties].map(({id, insurance_adjuster_name}) => ({
      value: `${id}`,
      label: insurance_adjuster_name,
    }));
  };

  involvedParties = () => {
    const {accident} = this.state;
    return [...(accident?.parties_involved || [])].map(
      ({id, driver_first_name, driver_last_name}) => ({
        value: `${id}`,
        label: `${driver_first_name} ${driver_last_name}`,
      })
    );
  };

  render() {
    const {generating, claimPartyId, partyInvolvedId, visible} = this.state;
    return (
      <GenerateDemandLetterModal
        claimParties={this.claimParties()}
        claimPartyId={claimPartyId}
        generating={generating}
        onChange={this.change}
        onClose={this.hide}
        onGenerate={this.generateDemandLetter}
        parties={this.involvedParties()}
        partyInvolvedId={partyInvolvedId}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({
  accidentParties: state.accident.accidentParties,
}))(subscriptionHOC(GenerateDemandLetterContainer));
