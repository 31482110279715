import React from 'react';
import PropTypes from 'prop-types';

// accident containers
import AccidentStateManagerContainer from '../../containers/AccidentStateManagerContainer/AccidentStateManagerContainer';

// driver routes
import driverRoute from '../../../driver/pages/DriverPage/route';

// Components
import {Heading, Container, Row, Column, Tabs} from '@matthahn/sally-ui';

// import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
// import AccidentTags from '../AccidentTags/AccidentTags';
import BlackText from './components/BlackText';
import PageContainer from './components/Container';
import Header from './components/Header';
import Content from './components/Content';
import HeaderContent from './components/HeaderContent';
import Big from './components/Big';
import Small from './components/Small';
import Space from './components/Space';
import SpaceTag from './components/SpaceTag';
// import StyledLink from './components/StyledLink';
// import Box from './components/Box';
// import Right from './components/Right';
import TabContainer from './components/TabContainer';

// react router dom
import {Link} from 'react-router-dom';

// Types
import {dateTime} from '@matthahn/sally-fw/lib/type';

// vehicle routes
import vehicleRoute from '../../../vehicle/pages/VehiclePage/route';

const AccidentPageLayout = ({
  accident,
  addon = [],
  children,
  datetime_of_loss,
  driver,
  medallion,
  onTab,
  tab,
  tabs,
  vehicle,
}) => (
  <PageContainer>
    <Header>
      <Container>
        <Row>
          <Column>
            <HeaderContent>
              <div>
                <Big>
                  <Heading size="1">
                    {!!driver ? (
                      <Link to={driverRoute(driver.id)}>
                        <BlackText>
                          {driver?.first_name} {driver?.last_name}
                        </BlackText>
                      </Link>
                    ) : (
                      'Unknown driver'
                    )}{' '}
                    on{' '}
                    {!!vehicle ? (
                      <Link to={vehicleRoute(vehicle.id)}>
                        <BlackText>
                          {medallion?.medallion_number ||
                            vehicle?.plate ||
                            'Unknown vehicle'}
                        </BlackText>
                      </Link>
                    ) : (
                      'Unknown vehicle'
                    )}
                  </Heading>
                  <Space flex>
                    <SpaceTag theme="darkGrey" inline>
                      <AccidentStateManagerContainer />
                    </SpaceTag>
                  </Space>
                </Big>
                <Small>{dateTime(datetime_of_loss).format()}</Small>
              </div>
            </HeaderContent>
          </Column>
        </Row>
      </Container>
      <TabContainer>
        <Tabs
          tab={tab}
          tabs={tabs}
          onChange={onTab}
          theme="orange"
          addon={(Addon) =>
            addon.map(({key, component}) => (
              <Addon key={key}>{component}</Addon>
            ))
          }
          nav
        />
      </TabContainer>
    </Header>
    <Content>{children}</Content>
  </PageContainer>
);

AccidentPageLayout.propTypes = {
  accident: PropTypes.object,
  addon: PropTypes.array,
  children: PropTypes.node,
  datetime_of_loss: PropTypes.string,
  driver: PropTypes.object,
  medallion: PropTypes.object,
  onTab: PropTypes.func,
  tab: PropTypes.string,
  tabs: PropTypes.array,
  vehicle: PropTypes.object,
};

export default AccidentPageLayout;
