import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/file.profile.api';

export default ({accidentId, partyInvolvedId, claimPartyId}) =>
  f3tch(
    `/accidents/dol/${accidentId}/party_involved/${partyInvolvedId}/generate_demand_letter/${claimPartyId}/`
  )
    .profile(profile())
    .get();
