import React, {Component} from 'react';
import PropTypes from 'prop-types';

// accident lib
import getRecentQueryDate from '../../lib/getRecentQueryDate.lib.amount';

// accident states
import fileReviewState from '../../../accident/state/file_review.state.accident';
import pending4By4State from '../../../accident/state/pending_4x4.state.accident';

// Accident
import accidentRoute from '../../pages/AccidentPage/route';

// Alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
import {isNull} from '../../../api/queries/queries';
import listAccidentsApi from '../../../accident/api/list.api.accident';

// Components
import AccidentsList from '../../components/AccidentsList/AccidentsList';

// Events
import startNewAccidentFlowEvt from '../../events/startNewAccidentFlow.event.accident';
import accidentCreatedEvt from '../../../accident/events/created.event.accident';

// Libs
import numberOfPages from '../../../lib/numberOfPages';

// react redux
import {connect} from 'react-redux';

// Router
import {withRouter} from 'react-router-dom';

// Subscribe
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// Query API
import sortQuery from '../../../api/lib/sortQuery.lib.api';

class AccidentsContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    manualStates: PropTypes.array,
    subscribe: PropTypes.func,
    users: PropTypes.array,
  };

  static OPTIONS = {
    perPage: 20,
  };

  static DEFAULT_SORT = {
    key: 'datetime_of_loss',
    direction: 'desc',
  };

  state = {
    accidents: [],
    loading: false,
    page: 1,
    query: {},
    search: '',
    sort: {...this.constructor.DEFAULT_SORT},
    state: 'all',
    user: null,
    users: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(accidentCreatedEvt.subscribe(this.accidentCreated));
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    this.getAccidents();
  };

  accidentCreated = () => {
    this.getAccidents({page: 1});
  };

  accidentDeleted = ({id}) => {
    const accidents = [...this.state.accidents].filter(
      (accident) => accident.id !== id
    );
    this.setState({accidents});
  };

  getAccidents = async ({
    page = this.state.page,
    sort = this.state.sort,
    search = this.state.search,
    user = this.state.user,
    query = this.state.query,
  } = {}) => {
    const {perPage} = this.constructor.OPTIONS;
    this.setState({
      accidents: [],
      loading: true,
      page,
      query,
      search,
      sort,
      user,
    });

    const actualQuery = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
      ...query,
      ...this.generateQuery({user, search}),
    };

    try {
      const {results, count} = await listAccidentsApi(actualQuery);
      if (!this.mounted) return;
      this.setState({accidents: results, loading: false, results: count});
    } catch (error) {
      if (!this.mounted) return;
      alert.info(`Failed to fetch accidents: ${error}`);
      this.setState({loading: false, accidents: [], results: 0});
    }
  };

  generateQuery = ({user, search} = this.state) => {
    const actualQuery = {};
    if (!!user && user !== 'all') actualQuery.dolclaim__assigned_user = user;
    if (!!search.trim().length) actualQuery.search = search;
    return actualQuery;
  };

  onSearch = (search) => this.getAccidents({search, page: 1});

  onPage = (page) => this.getAccidents({page});

  onSort = (sort) => this.getAccidents({page: 1, sort});

  onAccident = (accident) => () => {
    this.props.history.push(accidentRoute(accident.id));
  };

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  startAccidentFlow = () => {
    startNewAccidentFlowEvt.publish();
  };

  states = () => {
    const {manualStates} = this.props;
    return [
      {
        value: 'all',
        label: 'All',
        query: {},
      },
      {
        value: 'recent',
        label: 'Recent',
        query: {
          created_at__gte: getRecentQueryDate(),
        },
      },
      {
        value: 'pending4x4',
        label: 'Pending 4x4',
        query: {
          accidentstate__auto_state: pending4By4State.key,
          [isNull('accidentstate__manual_state')]: true,
        },
      },
      {
        value: fileReviewState.key,
        label: fileReviewState.label,
        query: {
          accidentstate__auto_state: fileReviewState.key,
          [isNull('accidentstate__manual_state')]: true,
        },
      },
      ...[...manualStates].map((manualState) => ({
        value: manualState.value,
        label: manualState.label,
        query: {
          accidentstate__manual_state: manualState.value,
        },
      })),
    ];
  };

  changeUser = (userId) => {
    this.getAccidents({page: 1, user: userId});
  };

  changeState = (stateValue) => {
    const state = [...this.states()].find(({value}) => value === stateValue);
    if (!state) return;
    this.setState({state: stateValue});
    this.getAccidents({page: 1, query: state.query});
  };

  actions = () => {
    return [
      {
        key: 'create',
        children: 'New Accident',
        icon: 'plus',
        theme: 'orange',
        onClick: this.startAccidentFlow,
      },
    ];
  };

  filters = () => {
    const {users} = this.props;
    const {state, user} = this.state;
    return [
      {
        key: 'users',
        label: 'Users',
        type: 'select',
        value: user || 'all',
        notFilterable: false,
        filterSize: 'extraLarge',
        options: [
          {
            value: 'all',
            label: 'All',
          },
          ...users.map(({id, username}) => ({value: `${id}`, label: username})),
        ],
        onChange: this.changeUser,
      },
      {
        key: 'state',
        label: 'State',
        type: 'select',
        value: state || 'all',
        options: [...this.states()],
        onChange: this.changeState,
      },
    ];
  };

  claimsDiaryManualStates = () => {
    const {manualStates} = this.props;
    return [...manualStates]
      .filter(({value}) => !value.startsWith('closed_'))
      .map(({value}) => value);
  };

  render() {
    const {users} = this.props;
    const {loading, accidents, page, sort, search} = this.state;
    return (
      <AccidentsList
        accidents={accidents}
        actions={this.actions()}
        claimsDiaryManualStates={this.claimsDiaryManualStates()}
        filters={this.filters()}
        loading={loading}
        onAccident={this.onAccident}
        onPage={this.onPage}
        onSearch={this.onSearch}
        onSort={this.onSort}
        page={page}
        pages={this.pages()}
        query={this.generateQuery()}
        search={search}
        sort={sort}
        users={users}
      />
    );
  }
}

export default connect((state) => ({
  manualStates: state.accident.manualStates,
  users: state.user.users,
}))(withRouter(subscriptionHoc(AccidentsContainer)));
