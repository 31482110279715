import styled from 'styled-components';

// colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const TableHeader = styled.div`
  width: 100%;
  height: 26px;
  position: fixed;
  top: 190px;
  left: 0px;
  z-index: 1;
  background: ${white};
`;

export default TableHeader;
