import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// API
import createTransactionApi from '../../api/create.api.transaction';
import getDriverByIDApi from '../../../driver/api/getByID.api.driver';

// Actions
import {hide as hideAct} from './redux/actions';

// Attributes
import amountAttr from '../../attributes/amount.attribute.transaction';
import subtypeAttr from '../../attributes/chargeSubtype.attribute.transaction';
import descriptionAttr from '../../attributes/description.attribute.transaction';
import driverAttr from '../../attributes/driver.attribute.transaction';

// Components
import ChargeModal from '../../components/ChargeModal/ChargeModal';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import getTransactionSubtypes from '../../../transactionType/lib/getSubtype.lib.transactionType';

// Prep
import chargePrep from '../../preparation/charge.preparation.transaction';

// Permission
import chargePermission from '../../permission/charge.permission.transaction';

// UI
const {alert} = lib;

const attributes = {
  amount: amountAttr,
  subtype: subtypeAttr,
  description: descriptionAttr,
};

// Initial state
const initialState = () => ({
  loading: false,
  amount: amountAttr(''),
  subtype: subtypeAttr(''),
  description: descriptionAttr(''),
});

class ChargeContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    driver: PropTypes.object,
    loadingTransactionTypes: PropTypes.bool,
    manualVisibilityControl: PropTypes.bool,
    onChargeCreated: PropTypes.func,
    onClose: PropTypes.func,
    predefinedValues: PropTypes.object,
    requestAddon: PropTypes.object,
    transactionTypes: PropTypes.array,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    manualVisibilityControl: false,
    onChargeCreated: () => null,
    predefinedValues: {},
    requestAddon: {},
  };

  state = {...initialState()};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const {predefinedValues} = this.props;
    const state = Object.entries(predefinedValues).reduce(
      (combined, [key, value]) =>
        !!attributes[key]
          ? {...combined, [key]: attributes[key](value)}
          : combined,
      {...initialState()}
    );
    this.setState(state);
  };

  hide = () => {
    const {onClose} = this.props;
    if (this.state.loading) return;
    if (!!onClose) return onClose();
    this.props.dispatch(hideAct(false));
  };

  change = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {visible, dispatch, driver, requestAddon, onChargeCreated, onClose} =
      this.props;

    const {loading, amount, subtype, description} = this.state;

    if (!chargePermission())
      return alert.warning('You do not have permissions to perform this.');
    if (!visible || !driver || loading) return;

    this.setState({loading: true});

    try {
      const charge = await chargePrep({
        amount,
        balance: amount,
        subtype,
        description,
        driver: driverAttr(driver.id),
      });
      charge.balance = charge.amount;
      const transaction = await createTransactionApi({
        ...charge,
        ...requestAddon,
      });
      await getDriverByIDApi(driver.id);
      this.setState({loading: false});
      alert.success('Charge created');
      onChargeCreated(transaction);
      if (!!onClose) return onClose();
      dispatch(hideAct(true));
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, loadingTransactionTypes, transactionTypes} = this.props;
    const {loading, amount, subtype, description} = this.state;

    return (
      <ChargeModal
        visible={visible}
        loading={loading || loadingTransactionTypes}
        amount={amount}
        subtype={subtype}
        subtypes={getTransactionSubtypes(transactionTypes, 'charge')}
        description={description}
        onSave={this.save}
        onChange={this.change}
        onClose={this.hide}
      />
    );
  }
}

export default connect((state, props) => ({
  ...state.charge,
  visible: props.manualVisibilityControl
    ? props.visible || false
    : state.charge.visible,
  requestAddon: props.requestAddon || state.charge.requestAddon,
  driver: props.driver || state.charge.driver,
  roles: state.auth.roles,
  loadingTransactionTypes: state.transactionType.loading,
  transactionTypes: state.transactionType.transactionTypes,
}))(ChargeContainer);
