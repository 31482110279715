import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Libs
import {colorKey} from '../../../lib/colorKey.lib.note';
import {lib} from '@matthahn/sally-ui';

// Components
import {Avatar, Icon} from '@matthahn/sally-ui';

// layout components
import TextArea from '../../../../layout/components/TextArea/TextArea';

// Attributes
import {dateTime} from '../../../../types';

// Colors
const {
  colors: {darkGrey, black, red},
} = lib;

// Helper components
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

const TextContainer = styled.div`
  width: 100%;
  padding-left: 20px;
`;

const SubData = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  color: ${darkGrey};
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  font-weight: 700;
  white-space: nowrap;
`;

const Line = styled.span`
  display: inline-block;
  margin: 0px 5px;
`;

const Action = styled.span`
  cursor: pointer;
  &:hover {
    color: ${black};
  }
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${red};
  font-size: 24px !important;
  background: rgba(255, 255, 255, 0.9);
`;

const Note = ({
  note,
  edit,
  editing,
  onSave,
  onEdit,
  onCancel,
  onDelete,
  onUpdateText,
}) => (
  <Container>
    <div>
      <Avatar
        avatar={note.owner.username}
        theme={colorKey(note.owner.username)}
      />
    </div>
    <TextContainer>
      {edit !== null ? (
        <TextArea
          value={edit?.text?.api?.format?.() || ''}
          onChange={(e) => onUpdateText(edit?.text?.reinit(e))}
          placeholder="Enter your note here.."
          disabled={editing}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: note.text.split('\n').join('<br />'),
          }}
        />
      )}
      <SubData>
        <div>
          by {note.owner.username} on {dateTime(note.date_created).format()}
        </div>
        {edit !== null ? (
          <Actions>
            <Action onClick={onSave}>Save</Action>
            <Line>|</Line>
            <Action onClick={onCancel}>Cancel</Action>
          </Actions>
        ) : (
          <Actions>
            <Action onClick={onEdit}>Edit</Action>
            <Line>|</Line>
            <Action onClick={onDelete}>Delete</Action>
          </Actions>
        )}
      </SubData>
    </TextContainer>
    {editing && (
      <Loader>
        <Icon icon="loading2" spin />
      </Loader>
    )}
  </Container>
);

Note.propTypes = {
  note: PropTypes.object,
  edit: PropTypes.object,
  editing: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateText: PropTypes.func,
};

export default Note;
