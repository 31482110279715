// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// redux
import {connect} from 'react-redux';
class ReceiptStateContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    children: PropTypes.func,
    receipts: PropTypes.array,
  };

  parseState = () => {
    const {receipts} = this.props;
    return !!receipts.length
      ? {state: 'completed', label: 'Uploaded'}
      : {state: 'warning', label: 'Pending upload'};
  };

  render() {
    const {children} = this.props;
    return !!children ? children({state: this.parseState()}) : null;
  }
}

export default connect((state) => ({receipts: state.accident.receipts}))(
  ReceiptStateContainer
);
