import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// accident containers
import DeleteAccidentContainer from '../../containers/DeleteAccidentContainer/DeleteAccidentContainer';
import GenerateDemandLetterContainer from '../../containers/GenerateDemandLetterContainer/GenerateDemandLetterContainer';

// accident sockets
import accidentUpdatedSocket from '../../sockets/updated.socket.accident';

// accidentParty api
import listAccidentPartiesApi from '../../../accidentParty/api/list.api.accidentParty';

// Api
import getAccidentByIdApi from '../../api/getById.api.accident';
import listDocumentsApi from '../../../document/api/list.api.document';
import listNotesApi from '../../../accidentNote/api/list.api.accidentNote';

// Actions
import {
  set as setAct,
  updateAccident as updateAccidentAct,
} from '../../redux/actions';

// Components
import {Button} from '@matthahn/sally-ui';
import AccidentLoader from '../../components/AccidentLoader/AccidentLoader';
import AccidentPageLayout from '../../components/AccidentPageLayout/AccidentPageLayout';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// Libs
import {lib} from '@matthahn/sally-ui';
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';
import fileBundlePrep from '../../../lib/fileBundlePrep';
import getAccidentState from '../../lib/getState.lib.accident';

// Route
import accidentsListRoute from '../../pages/AccidentsPage/route';

// AccidentInfo
import AccidentInfoPage from '../../pages/AccidentInfoPage/AccidentInfoPage';
import routeAccidentInfo from '../../pages/AccidentInfoPage/route';

// AccidentClaims
import AccidentClaimsPage from '../../pages/AccidentClaimsPage/AccidentClaimsPage';
import routeAccidentClaims from '../../pages/AccidentClaimsPage/route';

// AccidentDocuments
import AccidentDocumentsPage from '../../pages/AccidentDocumentsPage/AccidentDocumentsPage';
import routeAccidentDocuments from '../../pages/AccidentDocumentsPage/route';

// AccidentNotes
import AccidentNotesPage from '../../pages/AccidentNotesPage/AccidentNotesPage';
import routeAccidentNotes from '../../pages/AccidentNotesPage/route';

// AccidentRedirect
import AccidentRedirectPage from '../../pages/AccidentRedirectPage/AccidentRedirectPage';
import routeAccidentRedirect from '../../pages/AccidentRedirectPage/route';

// Documents
import accidentFileBundle from '../../../accident/documents/bundles/accident.bundle.accident';

import driverStatementDocument from '../../../accident/documents/folders/driverStatement.document.accident';
import policeReportDocument from '../../../accident/documents/folders/policeReport.document.accident';
import photoDocument from '../../../accident/documents/folders/photo.document.accident';
import receiptDocument from '../../../accident/documents/folders/receipt.document.accident';

// samson api
import listSamsonTicketsApi from '../../../vehicle/api/getSamsonTickets.api.vehicle';

// Alert
const {alert} = lib;

class AccidentContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    loadingAccident: PropTypes.bool,
    accident: PropTypes.object,
    loadingSamsonTickets: PropTypes.bool,
    samsonTickets: PropTypes.array,
    loadingNotes: PropTypes.bool,
    notes: PropTypes.array,
    loadingDriverStatement: PropTypes.bool,
    driverStatement: PropTypes.object,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init(this.props.id);
    this.props.subscribe(accidentUpdatedSocket.subscribe(this.accidentUpdated));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) this.init(this.props.id);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = true;
  apiID = v4();

  init = (id) => {
    this.apiID = v4();
    this.getAccident(id);
    this.getNotes(id);
    this.getScenePhotos(id);
    this.getReceipts(id);
    this.getDriverStatement(id);
    this.getPoliceReport(id);
  };

  accidentUpdated = (accident) => {
    if (accident.id !== this.props.accident?.id) return;
    this.props.dispatch(updateAccidentAct(accident));
  };

  onAccidentListed = (...accidents) => {
    if (!this.props.accident) return;
    const accident = [...accidents].find(
      ({id}) => id === this.props.accident.id
    );
    if (!accident || !accident.accessed_at) return;
    this.props.dispatch(updateAccidentAct(accident));
  };

  stop = (apiID) => this.apiID !== apiID || !this.mounted;

  getAccident = async (id) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingAccident: true}));

    try {
      const accident = await getAccidentByIdApi(id);
      await this.getSamsonTickets(accident);
      const {results: accidentParties} = await (!!accident?.dolclaim
        ? listAccidentPartiesApi(accident?.dolclaim?.id)
        : {results: []});
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingAccident: false, accident, accidentParties}));
    } catch (error) {
      if (this.stop(apiID)) return;
      alert.warning('This accident does not exist');
      this.props.history.replace(accidentsListRoute());
    }
  };

  getNotes = async (accident) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingNotes: true}));

    try {
      const {results: notes} = await listNotesApi(accident, {
        ordering: '-date_created',
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingNotes: false, notes}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingNotes: false}));
    }
  };

  getSamsonTickets = async (accident) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingSamsonTickets: true}));

    try {
      const {results: samsonTickets} = await listSamsonTicketsApi(
        fkOrId(accident.vehicle),
        {
          ordering: '-created_at',
        }
      );
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingSamsonTickets: false, samsonTickets}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingSamsonTickets: false}));
    }
  };

  getScenePhotos = async (accident) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingPhotos: true}));

    try {
      const {results: photos} = await listDocumentsApi({
        accident,
        type: photoDocument.type,
        ordering: '-created_at',
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingPhotos: false, photos}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingPhotos: false}));
    }
  };

  getReceipts = async (accident) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingReceipts: true}));

    try {
      const {results: receipts} = await listDocumentsApi({
        accident,
        type: receiptDocument.type,
        ordering: '-created_at',
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingReceipts: false, receipts}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingReceipts: false}));
    }
  };

  getDriverStatement = async (accident) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingDriverStatement: true}));

    try {
      const {results: driverStatements} = await listDocumentsApi({
        accident,
        type: driverStatementDocument.type,
        ordering: '-created_at',
      });
      if (this.stop(apiID)) return;
      const [driverStatement] = driverStatements;
      dispatch(
        setAct({
          loadingDriverStatement: false,
          driverStatement: driverStatement || null,
        })
      );
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingDriverStatement: false}));
    }
  };

  getPoliceReport = async (accident) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingPoliceReport: true}));

    try {
      const {results: policeReports} = await listDocumentsApi({
        accident,
        type: policeReportDocument.type,
        ordering: '-created_at',
      });
      if (this.stop(apiID)) return;
      const [policeReport] = policeReports;
      dispatch(
        setAct({
          loadingPoliceReport: false,
          policeReport: policeReport || null,
        })
      );
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingPoliceReport: false}));
    }
  };

  tabs = () => {
    const {
      accident: {id},
      notes,
    } = this.props;
    return [
      {
        id: routeAccidentInfo(id),
        label: 'Info',
      },
      {
        id: routeAccidentClaims(id),
        label: 'Claims',
      },
      {
        id: routeAccidentDocuments(id),
        label: 'Documents',
      },
      {
        id: routeAccidentNotes(id),
        label: 'Notes',
        notifications: notes.length,
      },
    ];
  };

  onTab = (route) => this.props.history.push(route);

  onAccidentBundle = () => {
    const {accident} = this.props;
    fileBundlePrep(
      {accident: accidentFileBundle},
      {
        accidents: [{...accident}],
        drivers: [{...accident.driver}],
        vehicles: [{...accident.vehicle}],
        rentals: [{...accident.rental}],
        name: accident.vehicle.svid,
      }
    );
  };

  addon = () => [
    {
      key: 'delete',
      component: <DeleteAccidentContainer accident={this.props.accident} />,
    },
    {
      key: 'bundle',
      component: (
        <Button
          theme="grey"
          size="small"
          icon="folder1"
          onClick={this.onAccidentBundle}
        >
          File bundle
        </Button>
      ),
    },
  ];

  render() {
    const {
      id,
      loadingAccident,
      loadingNotes,
      loadingPhotos,
      loadingDriverStatement,
      loadingPoliceReport,
      loadingSamsonTickets,
      accident,
      location: {pathname},
    } = this.props;
    return loadingAccident ||
      loadingNotes ||
      loadingPhotos ||
      loadingDriverStatement ||
      loadingPoliceReport ||
      loadingSamsonTickets ||
      !accident ||
      (!!accident && `${accident.id}` !== id) ? (
      <AccidentLoader />
    ) : (
      <AccidentPageLayout
        accident={accident}
        datetime_of_loss={accident.datetime_of_loss}
        driver={accident.driver}
        vehicle={accident.vehicle}
        medallion={accident.medallion}
        accident_state={getAccidentState(accident)}
        tab={pathname}
        tabs={this.tabs()}
        onTab={this.onTab}
        addon={this.addon()}
      >
        <Switch>
          <Route
            exact
            path={routeAccidentInfo()}
            component={AccidentInfoPage}
          />
          <Route
            exact
            path={routeAccidentClaims()}
            component={AccidentClaimsPage}
          />
          <Route
            exact
            path={routeAccidentDocuments()}
            component={AccidentDocumentsPage}
          />
          <Route
            exact
            path={routeAccidentNotes()}
            component={AccidentNotesPage}
          />
          <Route
            path={routeAccidentRedirect()}
            component={AccidentRedirectPage}
          />
        </Switch>
        <GenerateDemandLetterContainer />
      </AccidentPageLayout>
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...state.accident,
  }))(subscriptionHOC(AccidentContainer))
);
