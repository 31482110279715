import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/noData.profile.api';

// Events
import event from '../events/deleted.event.accidentParty';

export default async (partyId) => {
  const response = await f3tch(`/accidents/dol/claim/update_party/${partyId}/`)
    .profile(profile())
    .delete();
  event.publish(partyId);
  return response;
};
