import styled from 'styled-components';

// colors
import {black} from '@matthahn/sally-ui/lib/libs/colors';

const BlackText = styled.div`
  display: inline-block;
  color: ${black};
`;

export default BlackText;
