const columns = ({displayDriver = false, displayVehicle = false} = {}) =>
  [
    {
      key: 'date',
      label: 'Date',
    },
    displayDriver && {
      key: 'driver',
      label: 'Driver',
    },
    displayVehicle && {
      key: 'vehicle',
      label: 'Vehicle',
    },
    {
      key: 'rideshare',
      label: 'Rideshare',
    },
  ].filter((column) => !!column);

export default columns;
