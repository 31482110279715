import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'fault_reason',
  label: {
    default: 'Fault Reason',
    short: 'Reason',
  },
});
