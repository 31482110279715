import {fileName, folder, date} from '../../../document';

export default folder({
  defaultFileName: 'Receipt',
  // Receipt - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `Receipt - ${accident.driver?.first_name}_${
        accident.driver?.last_name
      } - ${
        accident?.medallion?.medallion_number || accident?.vehicle?.plate
      } - ${date()}`
  ),
  folderName: 'Receipts',
  type: 'accidentReceipt',
});
