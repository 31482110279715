import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Modal,
  Table,
  Icon,
  TextColor,
  Heading,
  Switch,
} from '@matthahn/sally-ui';
import Loader from '../../../layout/components/Loader/Loader';

import Wrap from './components/Wrap';
import Text from './components/Text';
import Div from './components/Div';
import SelectAll from './components/SelectAll';

// Columns
import columns from './columns';

const DocBundleManager = ({
  visible,
  loading,
  bundle,
  selected,
  data,
  filesExist,
  closable,
  onSelect,
  onSelectAll,
  onBundle,
  onPrint,
  onEmail,
  onClose,
}) => (
  <Modal
    visible={visible}
    title={bundle || 'File Bundle'}
    subtitle={!!bundle ? '' : 'Download or email file bundles'}
    icon="file1"
    closable={closable}
    onClose={onClose}
    headerActions={[
      // {
      //   icon: 'printer',
      //   onClick: onPrint,
      //   tooltip: 'Print',
      //   disabled: !!loading && loading !== 'print',
      //   loading: loading === 'print',
      // },
      {
        icon: 'mail',
        onClick: onEmail,
        tooltip: 'Email',
        disabled: !!loading && loading !== 'email',
        loading: loading === 'email',
      },
      {
        icon: 'download',
        onClick: onBundle,
        tooltip: 'Download',
        disabled: !!loading && loading !== 'bundle',
        loading: loading === 'bundle',
      },
    ]}
  >
    {(Content) =>
      loading === 'loading' ? (
        <Content padding="none">
          <Loader />
        </Content>
      ) : (
        [
          ...data.map((entry) => (
            <Content key={entry.id}>
              <Heading>{!!bundle ? '' : entry.name}</Heading>
              <Table
                columns={columns}
                height={300}
                noBorder
                noRadius
                smallNoResultsLabel
              >
                {(Row) =>
                  entry.files.map((file) => (
                    <Row key={file.id}>
                      {(Column) => [
                        <Column
                          key={file.id}
                          onClick={file.exists ? () => onSelect(file.id) : null}
                        >
                          <Wrap>
                            <Text>
                              <TextColor
                                theme={file.exists ? 'green' : 'darkGrey'}
                              >
                                <Icon icon={file.exists ? 'check' : 'close'} />
                              </TextColor>{' '}
                              {file.name}
                            </Text>
                            {file.exists && (
                              <Div>
                                <Switch
                                  size="small"
                                  value={selected.includes(file.id)}
                                  onChange={() => null}
                                />
                              </Div>
                            )}
                          </Wrap>
                        </Column>,
                      ]}
                    </Row>
                  ))
                }
              </Table>
            </Content>
          )),
          filesExist && (
            <Content padding="horizontal" key="selectAll" noBorder>
              <SelectAll onClick={onSelectAll} />
            </Content>
          ),
        ].filter((cnt) => !!cnt)
      )
    }
  </Modal>
);

DocBundleManager.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.string,
  bundle: PropTypes.string,
  selected: PropTypes.array,
  data: PropTypes.array,
  filesExist: PropTypes.bool,
  closable: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onBundle: PropTypes.func,
  onPrint: PropTypes.func,
  onEmail: PropTypes.func,
  onClose: PropTypes.func,
};

export default DocBundleManager;
