// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Select from '@matthahn/sally-ui/lib/components/Select/Select';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const GenerateDemandLetterModal = ({
  claimParties,
  claimPartyId,
  generating,
  onChange,
  onClose,
  onGenerate,
  parties,
  partyInvolvedId,
  visible,
}) => (
  <Modal
    title="Generate Demand Letter"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {
        label: 'Generate',
        onClick: onGenerate,
        loading: generating,
        theme: 'orange',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <Select
              value={claimPartyId}
              onChange={onChange('claimPartyId')}
              disabled={generating}
              options={claimParties}
              notFilterable
            >
              Claim party
            </Select>
          </Column>
        </Row>
        <Row>
          <Column>
            <Select
              value={partyInvolvedId}
              onChange={onChange('partyInvolvedId')}
              disabled={generating}
              options={parties}
              notFilterable
            >
              Involved party
            </Select>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

GenerateDemandLetterModal.propTypes = {
  claimParties: PropTypes.array,
  claimPartyId: PropTypes.any,
  generating: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onGenerate: PropTypes.func,
  parties: PropTypes.array,
  partyInvolvedId: PropTypes.any,
  visible: PropTypes.bool,
};

export default GenerateDemandLetterModal;
