import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {uppercase} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'phone',
  display: uppercase,
  input: uppercase,
  label: {
    default: 'Phone Number',
    short: 'Phone',
  },
});
