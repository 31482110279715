import {fileName, folder, date} from '../../../document';

export default folder({
  defaultFileName: 'Liability Financials',
  // LIABILITY FINANCIALS - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `LIABILITY FINANCIALS - ${accident.driver?.first_name}_${
        accident.driver?.last_name
      } - ${
        accident?.medallion?.medallion_number || accident?.vehicle?.plate
      } - ${date()}`
  ),
  folderName: 'Liability Financials',
  type: 'liabilityFinancials',
});
