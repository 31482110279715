import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
import generateDriverStatementApi from '../../api/generateDriverStatement.api.accident';

// Components
import {Button} from '@matthahn/sally-ui';
import DriverStatementCard from '../../components/DriverStatementCard/DriverStatementCard';
import AttributeFileUploader from '../../../layout/components/AttributeFileUploader/AttributeFileUploader';

// Documents
import driverStatementDocument from '../../documents/folders/driverStatement.document.accident';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Lib
import downloadFile from '../../../lib/downloadFile';

class AccidentDriverStatementContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    driverStatement: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    uploadModalVisible: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  generate = async () => {
    const {accident} = this.props;
    if (this.state.loading) return;

    this.setState({loading: true});

    try {
      const driverStatement = await generateDriverStatementApi(accident.id);
      if (!this.mounted) return;
      downloadFile(
        driverStatement,
        `DriverStatement - ${accident?.driver?.first_name}${
          accident?.driver?.last_name
        } - ${
          accident?.medallion?.medallion_number || accident?.vehicle?.plate
        }.pdf`
      );
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
    }
    this.setState({loading: false});
  };

  showUploadModal = () => {
    if (this.state.loading) return;
    this.setState({uploadModalVisible: true, visible: false});
  };

  hideUploadModal = () => {
    if (this.state.loading) return;
    this.setState({uploadModalVisible: false});
  };

  driverStatementUploaded = ({files: [driverStatement]}) => {
    this.props.dispatch(setAct({driverStatement}));
    this.hideUploadModal();
  };

  showModal = () => this.setState({visible: true});

  hideModal = () => this.setState({visible: false});

  render() {
    const {accident, driverStatement} = this.props;
    const {loading, uploadModalVisible, visible} = this.state;
    return (
      <Fragment>
        <Button
          theme={!!driverStatement ? 'green' : 'grey'}
          size="small"
          onClick={this.showModal}
          icon={!!driverStatement ? 'check' : 'upload'}
        >
          {!!driverStatement ? 'Show' : 'Upload / Generate'}
        </Button>
        <DriverStatementCard
          visible={visible}
          closable={!uploadModalVisible}
          loading={loading}
          driverStatement={driverStatement}
          onUpload={this.showUploadModal}
          onGenerate={this.generate}
          onClose={this.hideModal}
        />
        <AttributeFileUploader
          accident={accident}
          title="Upload DriverStatement"
          type="accident"
          folderType={driverStatementDocument.type}
          visible={uploadModalVisible}
          onDone={this.driverStatementUploaded}
          onClose={this.hideUploadModal}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  accident: state.accident.accident,
  driverStatement: state.accident.driverStatement,
}))(AccidentDriverStatementContainer);
