import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// accident actions
import {updateAccident as updateAccidentAct} from '../../redux/actions';

// accident apis
import createNoChargeReasonApi from '../../api/createNoChargeReason.api.accident';
import deleteNoChargeReasonApi from '../../api/deleteNoChargeReason.api.accident';

// accident components
import AccidentsRepairCard from '../../components/AccidentsRepairCard/AccidentsRepairCard';
import NoChargeModal from '../../components/NoChargeModal/NoChargeModal';

// accident lib
import getChargeAmount from '../../lib/getChargeAmount.lib.accident';
import sumCharges from '../../lib/sumCharges.lib.accident';

// components
import {Button} from '@matthahn/sally-ui';

// containers
import ChargeContainer from '../../../transaction/containers/ChargeContainer/ChargeContainer';
// import CreditContainer from '../../../transaction/containers/CreditContainer/CreditContainer';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// lib
import sum from '@matthahn/sally-fw/lib/lib/sum';

// redux
import {connect} from 'react-redux';

// types
import amountType from '@matthahn/sally-fw/lib/type/types/amount.type';
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';
class AccidentRepairContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    history: PropTypes.object,
    samsonTickets: PropTypes.array,
    userId: PropTypes.number,
  };

  state = {
    charging: false,
    visible: false,
    chargeModalVisible: false,
    creditModalVisible: false,
    noChargeVisible: false,
    text: '',
  };

  openModal = () => this.setState({visible: true});

  closeModal = () => this.setState({visible: false});

  openCharge = () => {
    this.setState({chargeModalVisible: true, visible: false});
  };

  closeCharge = () => this.setState({chargeModalVisible: false});

  openCredit = () => {
    this.setState({creditModalVisible: true, visible: false});
  };

  closeCredit = () => this.setState({creditModalVisible: false});

  showNoChargeModal = () => {
    if (this.state.charging) return;
    this.setState({noChargeVisible: true, visible: false, text: ''});
  };

  hideNoChargeModal = () => {
    if (this.state.charging) return;
    this.setState({noChargeVisible: false});
  };

  charge = (charge) => {
    const {dispatch, accident} = this.props;
    const updatedAccident = {
      ...accident,
      charges: !!accident.charges ? [...accident.charges, charge] : [charge],
    };
    dispatch(updateAccidentAct(updatedAccident));
  };

  noCharge = async () => {
    const {accident, userId, dispatch} = this.props;
    const {charging, text} = this.state;

    if (charging) return;
    if (!text.trim().length) return alert.info('Insert reason');

    this.setState({charging: true});

    try {
      const nocharge_reason = await createNoChargeReasonApi({
        text,
        dol: accident.id,
        owner: userId,
      });
      const updatedAccident = {...accident, nocharge_reason};
      dispatch(updateAccidentAct(updatedAccident));
      this.setState({charging: false, noChargeVisible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({charging: false});
    }
  };

  deleteNoCharge = async () => {
    const {accident, dispatch} = this.props;
    const {charging} = this.state;

    if (charging || !accident?.nocharge_reason) return;

    this.setState({charging: true});

    try {
      await deleteNoChargeReasonApi(accident?.nocharge_reason?.id);
      const updatedAccident = {...accident, nocharge_reason: null};
      dispatch(updateAccidentAct(updatedAccident));
      this.setState({charging: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({charging: false});
    }
  };

  change = (text) => {
    if (this.state.charging) return;
    this.setState({text});
  };

  predefinedChargeValues = () => {
    const {accident, samsonTickets} = this.props;
    const {chargeModalVisible} = this.state;
    return {
      subtype: 'repair',
      amount: chargeModalVisible
        ? getChargeAmount({accident, tickets: samsonTickets})
        : 0,
      description: `Accident DOL - ${accident.id} - ${dateTimeType(
        accident.datetime_of_loss
      ).format()}`,
    };
  };

  predefinedCreditValues = () => {
    const {accident} = this.props;
    const {creditModalVisible} = this.state;
    return {
      amount: creditModalVisible ? sumCharges(accident.charges) : 0,
      description: `Accident DOL - ${accident.id} - ${dateTimeType(
        accident.datetime_of_loss
      ).format()}`,
    };
  };

  renderDom = () => {
    const {accident} = this.props;
    const chargeAmounts = [...(accident?.charges || [])].map(
      (charge) => charge.amount
    );
    const sumOfCharges = sum(...chargeAmounts);
    return !!accident?.charges?.length ? (
      <Button theme="green" size="small" onClick={this.openModal}>
        ${amountType(sumOfCharges).format()}
      </Button>
    ) : !!accident?.nocharge_reason ? (
      <Button theme="grey" size="small" onClick={this.openModal}>
        $0
      </Button>
    ) : (
      <Button theme="grey" size="small" onClick={this.openModal}>
        Charge
      </Button>
    );
  };

  render() {
    const {accident, samsonTickets} = this.props;
    const {
      visible,
      noChargeVisible,
      text,
      charging,
      chargeModalVisible,
      // creditModalVisible,
    } = this.state;

    return (
      <Fragment>
        {this.renderDom()}
        <AccidentsRepairCard
          visible={visible}
          charging={charging}
          onClose={this.closeModal}
          accident={accident}
          charges={accident?.charges || []}
          tickets={samsonTickets}
          onCharge={this.openCharge}
          onNoCharge={this.showNoChargeModal}
          onDeleteNoCharge={this.deleteNoCharge}
          onCredit={this.openCredit}
        />
        <NoChargeModal
          loading={charging}
          onChange={this.change}
          onClose={this.hideNoChargeModal}
          onSave={this.noCharge}
          text={text}
          visible={noChargeVisible}
        />
        <ChargeContainer
          driver={accident.driver}
          visible={chargeModalVisible}
          onClose={this.closeCharge}
          predefinedValues={this.predefinedChargeValues()}
          requestAddon={{dol: accident.id}}
          onChargeCreated={this.charge}
          manualVisibilityControl
        />
        {/* <CreditContainer
          driver={accident.driver}
          visible={creditModalVisible}
          onClose={this.closeCredit}
          predefinedValues={this.predefinedCreditValues()}
          creditProps={{dol: accident.id}}
        /> */}
      </Fragment>
    );
  }
}

export default connect((state) => ({
  accident: state.accident.accident,
  samsonTickets: state.accident.samsonTickets,
  userId: state.auth.userID,
}))(AccidentRepairContainer);
