import styled from 'styled-components';

// colors
import {white, grey} from '@matthahn/sally-ui/lib/libs/colors';

const Menu = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  top: 110px;
  left: 0px;
  z-index: 2;
  background: ${white};
  display: flex;
  border-bottom: 1px solid ${grey};
`;

export default Menu;
