import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// accident components
import PartiesInvolvedEditForm from '../../components/PartiesInvolvedEditForm/PartiesInvolvedEditForm';

// Components
import {Card} from '@matthahn/sally-ui';

const PartiesInvolvedCard = ({
  deletingPartyId,
  isAdding,
  onCancelAdding,
  onChange,
  onDelete,
  onNewParty,
  onSave,
  partiesInvolved,
  saving,
}) => (
  <Card
    title="Other Parties Involved"
    headerActions={
      isAdding
        ? []
        : [{icon: 'plus', tooltip: 'New Involved Party', onClick: onNewParty}]
    }
    buttonsRight={[
      {label: 'Save', loading: saving, onClick: onSave, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Fragment>
        {partiesInvolved.map((party) => (
          <Content key={party.id}>
            <PartiesInvolvedEditForm
              createView={party.id === 'create'}
              defaultVisible={
                party.id === 'create' || partiesInvolved.length <= 1
              }
              deleting={deletingPartyId === party.id}
              isAdding={isAdding}
              key={party.id}
              onCancelAdding={onCancelAdding}
              onChange={onChange(party.id)}
              onDelete={onDelete(party.id)}
              saving={saving}
              {...party.attributes}
            />
          </Content>
        ))}
      </Fragment>
    )}
  </Card>
);

PartiesInvolvedCard.propTypes = {
  deletingPartyId: PropTypes.any,
  isAdding: PropTypes.bool,
  onCancelAdding: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onNewParty: PropTypes.func,
  onSave: PropTypes.func,
  partiesInvolved: PropTypes.array,
  saving: PropTypes.bool,
};

export default PartiesInvolvedCard;
