// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// redux
import {connect} from 'react-redux';
class PhotosStateContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    children: PropTypes.func,
    photos: PropTypes.array,
  };

  parseState = () => {
    const {photos} = this.props;
    return !!photos.length
      ? {state: 'completed', label: 'Uploaded'}
      : {state: 'warning', label: 'Pending upload'};
  };

  render() {
    const {children} = this.props;
    return !!children ? children({state: this.parseState()}) : null;
  }
}

export default connect((state) => ({photos: state.accident.photos}))(
  PhotosStateContainer
);
