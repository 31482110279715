export default [
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'payment_method',
    label: 'Payment Method',
  },
  {
    key: 'total',
    label: 'Payout',
  },
  {
    key: 'balance',
    label: 'Balance at Payout',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'dispatcher',
    label: 'Cashier',
    span: 4,
  },
];
