// accidentParty api
import createAccidentPartyApi from '../../api/create.api.accidentParty';

// accidentParty attributes
import insuranceAdjusterContactInfoEmailAttribute from '../../attributes/insurance_adjuster_contact_info_email.attribute.accidentParty';
import insuranceAdjusterContactInfoPhoneAttribute from '../../attributes/insurance_adjuster_contact_info_phone.attribute.accidentParty';
import insuranceAdjusterNameAttribute from '../../attributes/insurance_adjuster_name.attribute.accidentParty';
import insuranceCarrierAttribute from '../../attributes/insurance_carrier.attribute.accidentParty';
import insuranceClaimNumberAttribute from '../../attributes/insurance_claim_number.attribute.accidentParty';
import partyTypeAttribute from '../../attributes/party_type.attribute.accidentParty';
// import ridesharePlatformAttribute from '../../attributes/rideshare_platform.attribute.accidentParty';

// accidentPart components
import AccidentPartyEditor from '../../components/AccidentPartyEditor/AccidentPartyEditor';

// accidentParty events
import accidentPartyCreatedEvent from '../../events/created.event.accidentParty';
import showAccidentPartyCreateModalEvent from '../../events/showCreateModal.event.accidentParty';

// accidentParty preparations
import createAccidentPartyPreparation from '../../preparations/create.preparation.accidentParty';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class CreateAccidentPartyContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    accident: null,
    insurance_adjuster_contact_info_email:
      insuranceAdjusterContactInfoEmailAttribute(''),
    insurance_adjuster_contact_info_phone:
      insuranceAdjusterContactInfoPhoneAttribute(''),
    insurance_adjuster_name: insuranceAdjusterNameAttribute(''),
    insurance_carrier: insuranceCarrierAttribute(''),
    insurance_claim_number: insuranceClaimNumberAttribute(''),
    party_type: partyTypeAttribute(''),
    // rideshare_platform: ridesharePlatformAttribute(''),
    saving: false,
    visible: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(
      showAccidentPartyCreateModalEvent.subscribe(this.show)
    );
  }

  show = ({accident}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      accident,
      // rideshare_platform: ridesharePlatformAttribute(
      //   accident?.rideshare_signed_in_service || ''
      // ),
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {
      accident,
      insurance_adjuster_contact_info_email,
      insurance_adjuster_contact_info_phone,
      insurance_adjuster_name,
      insurance_carrier,
      insurance_claim_number,
      party_type,
      // rideshare_platform,
      saving,
    } = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const accidentPartyBody = await createAccidentPartyPreparation({
        accident,
        insurance_adjuster_contact_info_email,
        insurance_adjuster_contact_info_phone,
        insurance_adjuster_name,
        insurance_carrier,
        insurance_claim_number,
        party_type,
        // rideshare_platform,
      });
      const accidentParty = await createAccidentPartyApi(
        accident.dolclaim.id,
        accidentPartyBody
      );
      accidentPartyCreatedEvent.publish({accident, accidentParty});
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {
      insurance_adjuster_contact_info_email,
      insurance_adjuster_contact_info_phone,
      insurance_adjuster_name,
      insurance_carrier,
      insurance_claim_number,
      party_type,
      saving,
      visible,
    } = this.state;
    return (
      <Modal
        title="Create Accident Party"
        visible={visible}
        onClose={this.hide}
        buttonsRight={[
          {label: 'Save', loading: saving, onClick: this.save, theme: 'orange'},
        ]}
      >
        {(Content) => (
          <Content padding="none">
            <AccidentPartyEditor
              insurance_adjuster_contact_info_email={
                insurance_adjuster_contact_info_email
              }
              insurance_adjuster_contact_info_phone={
                insurance_adjuster_contact_info_phone
              }
              insurance_adjuster_name={insurance_adjuster_name}
              insurance_carrier={insurance_carrier}
              insurance_claim_number={insurance_claim_number}
              onChange={this.change}
              party_type={party_type}
              saving={saving}
            />
          </Content>
        )}
      </Modal>
    );
  }
}

export default subscriptionHOC(CreateAccidentPartyContainer);
