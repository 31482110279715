import React from 'react';
import PropTypes from 'prop-types';

// accident containers
import AccidentManualStateLoaderContainer from '../../../accident/containers/AccidentManualStateLoaderContainer/AccidentManualStateLoaderContainer';

// ach container
import AchsPendingApprovalSync from '../../../ach/containers/AchsPendingApprovalSync/AchsPendingApprovalSync';

// alert containers
import CreateAlertContainer from '../../../alert/containers/CreateAlertContainer/CreateAlertContainer';
import FailedAutoPayoutsSync from '../../../alert/containers/FailedAutoPayoutsSync/FailedAutoPayoutsSync';

// cheque container
import ChequesPendingApprovalSync from '../../../cheque/containers/ChequesPendingApprovalSync/ChequesPendingApprovalSync';

// Components
import Container from './components/Container';
import Content from './components/Content';

// Containers
import SpotlightContainer from '../../../spotlight/containers/SpotlightContainer/SpotlightContainer';
import NavigationContainer from '../../containers/NavigationContainer/NavigationContainer';
import MedallionOwnerFetchContainer from '../../../medallionOwner/containers/MedallionOwnerFetchContainer/MedallionOwnerFetchContainer';
import ChargeContainer from '../../../transaction/containers/ChargeContainer/ChargeContainer';
import CreditContainer from '../../../transaction/containers/CreditContainer/CreditContainer';
import DispatchVehicleFlowContainer from '../../../dispatch/containers/DispatchVehicleFlowContainer/DispatchVehicleFlowContainer';
import PayoutDriverContainer from '../../../payout/containers/PayoutDriverContainer/PayoutDriverContainer';
import RentalReportPreviewContainer from '../../../rental/containers/RentalReportPreviewContainer/RentalReportPreviewContainer';
import SkipPayoutDriverContainer from '../../../payout/containers/SkipPayoutDriverContainer/SkipPayoutDriverContainer';
import TransactionTypesSyncContainer from '../../../transactionType/containers/TransactionTypesSyncContainer/TransactionTypesSyncContainer';
import ContractTypesSyncContainer from '../../../lease/containers/ContractTypesSyncContainer/ContractTypesSyncContainer';
import NumberOfTicketsSync from '../../../ticket/containers/NumberOfTicketsSync/NumberOfTicketsSync';
import NumberOfTollsSync from '../../../toll/containers/NumberOfTollsSync/NumberOfTollsSync';
import NumberOfPendingAchsSync from '../../../ach/containers/NumberOfPendingAchsSync/NumberOfPendingAchsSync';
import NumberOfPendingChequesSync from '../../../cheque/containers/NumberOfPendingChequesSync/NumberOfPendingChequesSync';
import HotswapContainer from '../../../rental/containers/HotswapContainer/HotswapContainer';
import PutVehicleOnHoldContainer from '../../../vehicleHold/containers/PutVehicleOnHoldContainer/PutVehicleOnHoldContainer';
import ReleaseVehicleFromHoldContainer from '../../../vehicleHold/containers/ReleaseVehicleFromHoldContainer/ReleaseVehicleFromHoldContainer';
import DecommissionVehicleContainer from '../../../vehicle/containers/DecommissionVehicleContainer/DecommissionVehicleContainer';
import RevertDecommissionVehicleContainer from '../../../vehicle/containers/RevertDecommissionVehicleContainer/RevertDecommissionVehicleContainer';
import EnableVehicleContainer from '../../../vehicle/containers/EnableVehicleContainer/EnableVehicleContainer';
import DriverFhvCaptureContainer from '../../../driver/containers/DriverFhvCaptureContainer/DriverFhvCaptureContainer';
import BlacklistDriverContainer from '../../../driver/containers/BlacklistDriverContainer/BlacklistDriverContainer';
import ArchiveVehicleContainer from '../../../vehicle/containers/ArchiveVehicleContainer/ArchiveVehicleContainer';
import EnableDriverContainer from '../../../driver/containers/EnableDriverContainer/EnableDriverContainer';
import StoreMedallionContainer from '../../../medallion/containers/StoreMedallionContainer/StoreMedallionContainer';
import UnmanageMedallionContainer from '../../../medallion/containers/UnmanageMedallionContainer/UnmanageMedallionContainer';
import EnableMedallionContainer from '../../../medallion/containers/EnableMedallionContainer/EnableMedallionContainer';
import TicketContainer from '../../../ticket/containers/TicketContainer/TicketContainer';
import NewTicketContainer from '../../../ticket/containers/NewTicketContainer/NewTicketContainer';
import ExternalDevicesContainer from '../../../externalDevice/containers/ExternalDevicesContainer/ExternalDevicesContainer';
import ReassignTollToVehicleContainer from '../../../toll/containers/ReassignTollToVehicleContainer/ReassignTollToVehicleContainer';
import ReassignTollToEmployeeContainer from '../../../toll/containers/ReassignTollToEmployeeContainer/ReassignTollToEmployeeContainer';
import SearchTicketContainer from '../../../ticket/containers/SearchTicketContainer/SearchTicketContainer';
import ContractContainer from '../../../document/containers/ContractContainer/ContractContainer';
import TakePaymentContainer from '../../../payment/containers/TakePaymentContainer/TakePaymentContainer';
import NewAccidentFlowContainer from '../../../accident/containers/NewAccidentFlowContainer/NewAccidentFlowContainer';

// contract containers
import ChangeOrderActionsContainer from '../../../contract/containers/ChangeOrderActionsContainer/ChangeOrderActionsContainer';
import SignChangeOrderContractContainer from '../../../contract/containers/SignChangeOrderContractContainer/SignChangeOrderContractContainer';

// document containers
import DocBundleManagerContainer from '../../../document/containers/DocBundleManagerContainer/DocBundleManagerContainer';

// insurance containers
import CreateInsuranceContainer from '../../../insurance/containers/CreateInsuranceContainer/CreateInsuranceContainer';
import EditInsuranceContainer from '../../../insurance/containers/EditInsuranceContainer/EditInsuranceContainer';

// permission containers
import PermissionsContainer from '../../../permission/containers/PermissionsContainer/PermissionsContainer';

// user containers
import UsersFetchContainer from '../../../user/containers/UsersFetchContainer/UsersFetchContainer';

// vehicle containers
import SelectVehiclesContainer from '../../../vehicle/containers/SelectVehiclesContainer/SelectVehiclesContainer';
import VehicleTypeFetchContainer from '../../../vehicle/containers/VehicleTypeFetchContainer/VehicleTypeFetchContainer';

const Layout = ({children}) => (
  <VehicleTypeFetchContainer>
    <Container>
      <NavigationContainer />
      <Content>{children}</Content>
      <UsersFetchContainer />
      <AccidentManualStateLoaderContainer />
      <MedallionOwnerFetchContainer />
      <ChangeOrderActionsContainer />
      <SignChangeOrderContractContainer />
      <ChargeContainer />
      <CreditContainer />
      <DispatchVehicleFlowContainer />
      <PayoutDriverContainer />
      <SkipPayoutDriverContainer />
      <RentalReportPreviewContainer />
      <TransactionTypesSyncContainer />
      <ContractTypesSyncContainer />
      <HotswapContainer />
      <TakePaymentContainer />
      <FailedAutoPayoutsSync />
      <AchsPendingApprovalSync />
      <ChequesPendingApprovalSync />
      <NumberOfTicketsSync />
      <NumberOfTollsSync />
      <NumberOfPendingAchsSync />
      <NumberOfPendingChequesSync />
      <PutVehicleOnHoldContainer />
      <ReleaseVehicleFromHoldContainer />
      <EnableVehicleContainer />
      <ArchiveVehicleContainer />
      <RevertDecommissionVehicleContainer />
      <DecommissionVehicleContainer />
      <DriverFhvCaptureContainer />
      <BlacklistDriverContainer />
      <EnableDriverContainer />
      <StoreMedallionContainer />
      <UnmanageMedallionContainer />
      <EnableMedallionContainer />
      <TicketContainer />
      <NewTicketContainer />
      <ExternalDevicesContainer />
      <ContractContainer />
      <ReassignTollToVehicleContainer />
      <ReassignTollToEmployeeContainer />
      <PermissionsContainer />
      <NewAccidentFlowContainer />
      <SearchTicketContainer />
      <SpotlightContainer />
      <CreateAlertContainer />
      <CreateInsuranceContainer />
      <DocBundleManagerContainer />
      <EditInsuranceContainer />
      <SelectVehiclesContainer />
    </Container>
  </VehicleTypeFetchContainer>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
