import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// accident api
import deleteAccidentApi from '../../api/delete.api.accident';

// accident components
import DeleteAccident from '../../components/DeleteAccident/DeleteAccident';

// accident permissions
import deleteAccidentPermission from '../../permissions/delete.permission.accident';

// accident routes
import accidentsRoute from '../../pages/AccidentsPage/route';

// accident sockets
import accidentDeletedSocket from '../../sockets/deleted.socket.accident';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOC
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// router
import {withRouter} from 'react-router-dom';

class DeleteAccidentContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    history: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(accidentDeletedSocket.subscribe(this.accidentDeleted));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  deleting = false;

  accidentDeleted = ({id}) => {
    const {accident, history} = this.props;
    if (this.deleting || accident?.id !== id) return;
    alert.info('DOL deleted');
    history.replace(accidentsRoute());
  };

  confirmDelete = () => {
    const {loading} = this.state;

    if (!deleteAccidentPermission())
      return alert.warning('You do not have permissions to perform this');

    if (loading) return;

    notify({
      id: 'delete',
      title: 'Confirm',
      icon: undefined,
      content: 'Are you sure you want to delete this DOL?',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Delete',
        onClick: this.delete,
      },
    });
  };

  delete = async () => {
    const {accident, history} = this.props;
    const {loading} = this.state;

    if (!deleteAccidentPermission())
      return alert.warning('You do not have permissions to perform this');

    if (loading) return;

    this.setState({loading: true});
    this.deleting = true;

    try {
      await deleteAccidentApi(accident.id);
      if (!this.mounted) return;
      alert.success('DOL deleted');
      history.replace(accidentsRoute());
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
      this.deleting = false;
    }
  };

  render() {
    const {loading} = this.state;
    return deleteAccidentPermission() ? (
      <DeleteAccident loading={loading} onClick={this.confirmDelete} />
    ) : null;
  }
}

export default withRouter(subscriptionHOC(DeleteAccidentContainer));
