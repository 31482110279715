// attribute lib
import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

// date lib
import convertDateToDateTime from '../../date/lib/convertDateToDateTime.lib.date';

export default async (attributes) => {
  const data = await prepare(attributes, {
    field: 'api',
    required: [],
  });
  if (!!data.rf_date_closed)
    data.rf_date_closed = convertDateToDateTime(data.rf_date_closed);
  return data;
};
