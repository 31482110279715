import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'fault_distribution',
  label: {
    default: 'Fault Distribution',
    short: 'Fault',
  },
  options: ['AT FAULT', '50/50', 'NOT AT FAULT'],
});
