// accident components
import AccidentTableHeader from '../../../accident/components/AccidentTableHeader/AccidentTableHeader';

// accident redux actions
import {set as setAccidentsAction} from '../../../accident/redux/actions';

// accidentParty api
import deleteAccidentPartyApi from '../../api/delete.api.accidentParty';

// accidentParty attributes
import partyTypeAttribute from '../../attributes/party_type.attribute.accidentParty';

// accidentParty containers
import CreateAccidentPartyContainer from '../CreateAccidentPartyContainer/CreateAccidentPartyContainer';
import EditAccidentPartyContainer from '../EditAccidentPartyContainer/EditAccidentPartyContainer';

// accidentParty events
import accidentPartyCreatedEvent from '../../events/created.event.accidentParty';

// components
import Card from '@matthahn/sally-ui/lib/components/Card/Card';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// react redux
import {connect} from 'react-redux';

class AccidentPartiesContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    accidentParties: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  state = {
    deleting: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(
      accidentPartyCreatedEvent.subscribe(this.addAccidentParty)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  groupParties = () => {
    const {accidentParties} = this.props;
    return [...partyTypeAttribute.additional.options]
      .map((type) => ({
        type,
        label: type.split('_').join(' '),
        parties: [...accidentParties].filter(
          (party) => party.party_type === type
        ),
      }))
      .filter(({parties}) => !!parties.length);
  };

  addAccidentParty = ({accidentParty}) => {
    const {accidentParties, dispatch} = this.props;
    dispatch(
      setAccidentsAction({accidentParties: [...accidentParties, accidentParty]})
    );
  };

  updateAccidentParty = (accidentParty) => {
    const {accidentParties, dispatch} = this.props;
    const updatedAccidentParties = [...accidentParties].map((party) =>
      party.id === accidentParty.id ? accidentParty : party
    );
    dispatch(setAccidentsAction({accidentParties: updatedAccidentParties}));
  };

  handleDelete = (accidentParty) => () => this.delete({accidentParty});

  delete = async ({accidentParty, prompt = true} = {}) => {
    const {dispatch} = this.props;
    const {deleting} = this.state;

    if (deleting.includes(accidentParty.id)) return;

    if (prompt)
      return notify({
        title: 'Confirm',
        content: 'Are you sure you want to delete this party?',
        primary: {
          label: 'Cancel',
          onClick: () => null,
        },
        secondary: {
          label: 'Delete',
          onClick: () => this.delete({accidentParty, prompt: false}),
        },
      });

    this.setState({deleting: [...deleting, accidentParty.id]});
    const stopDeleting = () =>
      [...this.state.deleting].filter((id) => id !== accidentParty.id);

    try {
      await deleteAccidentPartyApi(accidentParty.id);
      if (!this.mounted) return;
      const accidentParties = [...this.props.accidentParties].filter(
        ({id}) => id !== accidentParty.id
      );
      dispatch(setAccidentsAction({accidentParties}));
      this.setState({
        deleting: stopDeleting(),
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({
        deleting: stopDeleting(),
      });
    }
  };

  render() {
    const {accident} = this.props;
    const {deleting} = this.state;
    return (
      <Fragment>
        {this.groupParties().map(({type, label, parties}) => (
          <Fragment key={type}>
            <AccidentTableHeader>{label}</AccidentTableHeader>
            {parties.map((party) => (
              <Card
                key={party.id}
                title={party.insurance_adjuster_name}
                headerActions={[
                  {
                    icon: 'delete',
                    onClick: this.handleDelete(party),
                    loading: deleting.includes(party.id),
                    tooltip: 'Delete',
                  },
                ]}
                foldable
              >
                {(Content) => (
                  <Content padding="none">
                    <EditAccidentPartyContainer
                      accident={accident}
                      accidentParty={party}
                      onSaved={this.updateAccidentParty}
                    />
                  </Content>
                )}
              </Card>
            ))}
          </Fragment>
        ))}
        <CreateAccidentPartyContainer />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  accident: state.accident.accident,
  accidentParties: state.accident.accidentParties,
}))(subscriptionHOC(AccidentPartiesContainer));
