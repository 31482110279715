import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Table, Button, Info} from '@matthahn/sally-ui';

// local components
import ButtonsColumns from './components/ButtonsColumns';
import ButtonsRow from './components/ButtonsRow';

// local data
import chargeColumns from './data/chargeColumns';
import samsonColumns from './data/samsonColumns';

// samson lib
import sumItems from '../../../samson/lib/sum.lib.item';

// types
import {amount, dateTime} from '@matthahn/sally-fw/lib/type';

const AccidentsRepairCard = ({
  visible,
  onClose,
  accident,
  charging,
  charges,
  tickets,
  onCharge,
  onNoCharge,
  onDeleteNoCharge,
  onCredit,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Repairs"
    icon="car"
    info={
      !!accident.nocharge_reason
        ? (Content) => (
            <Content padding="none">
              <Info
                loading={charging}
                type="warning"
                action={{onClick: onDeleteNoCharge, label: 'Undo'}}
                flat
              >
                No charge! Reason: {accident?.nocharge_reason?.text}
              </Info>
            </Content>
          )
        : null
    }
    headerActions={
      !accident.nocharge_reason && !!charges.length
        ? [
            {tooltip: 'Add charge', icon: 'creditcard', onClick: onCharge},
            // {tooltip: 'Credit', icon: 'upload', onClick: onCredit},
          ].filter((act) => !!act)
        : []
    }
  >
    {(Content) => (
      <Fragment>
        {!accident.nocharge_reason && !charges.length && (
          <Content>
            <ButtonsRow>
              <ButtonsColumns>
                <Button
                  onClick={onCharge}
                  theme="orange"
                  icon="creditcard"
                  block
                >
                  Charge
                </Button>
              </ButtonsColumns>
              <ButtonsColumns>
                <Button onClick={onNoCharge} theme="grey" icon="close" block>
                  Don't Charge
                </Button>
              </ButtonsColumns>
            </ButtonsRow>
          </Content>
        )}
        {!!charges.length && (
          <Content padding="none">
            <Table
              columns={chargeColumns}
              noBorder
              noRadius
              smallNoResultsLabel
            >
              {(TableRow) =>
                charges.map((charge) => (
                  <TableRow key={charge.id}>
                    {(TableCol) =>
                      [
                        <TableCol key="date">
                          {dateTime(charge.created_at).format()}
                        </TableCol>,
                        <TableCol key="amount">
                          ${amount(charge.amount).format()}
                        </TableCol>,
                        <TableCol key="type">{charge.subtype}</TableCol>,
                      ].filter((col) => !!col)
                    }
                  </TableRow>
                ))
              }
            </Table>
          </Content>
        )}
        {!!tickets.length && (
          <Content padding="none">
            <Table
              columns={samsonColumns}
              noBorder
              noRadius
              smallNoResultsLabel
            >
              {(TableRow) =>
                tickets.map((ticket) => {
                  return (
                    <TableRow key={ticket.id}>
                      {(TableCol) => [
                        <TableCol key="created_at">
                          {dateTime(ticket.created_at).format()}
                        </TableCol>,
                        <TableCol key="id">#{ticket.id}</TableCol>,
                        <TableCol key="amount">
                          ${amount(sumItems(ticket.items)).format()}
                        </TableCol>,
                      ]}
                    </TableRow>
                  );
                })
              }
            </Table>
          </Content>
        )}
      </Fragment>
    )}
  </Modal>
);

AccidentsRepairCard.propTypes = {
  visible: PropTypes.bool,
  charging: PropTypes.bool,
  onClose: PropTypes.func,
  accident: PropTypes.object,
  charges: PropTypes.array,
  onCharge: PropTypes.func,
  onNoCharge: PropTypes.func,
  onDeleteNoCharge: PropTypes.func,
  onCredit: PropTypes.func,
};

export default AccidentsRepairCard;
