import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column, Table} from '@matthahn/sally-ui';
import PageContainer from './components/Container';
import Content from './components/Content';

// Columns
import columns from './columns';

// Types
import {dateTime} from '@matthahn/sally-fw/lib/type';

const AssociatedAccidentsContainer = ({
  loading,
  displayDriver,
  displayVehicle,
  accidents,
  page,
  pages,
  onPage,
  onAccident,
}) => (
  <PageContainer>
    <Content>
      <Container>
        <Row margin>
          <Column>
            <Table
              columns={columns({displayDriver, displayVehicle})}
              infinite={false}
              page={page}
              pages={pages}
              onPage={onPage}
              loading={loading}
            >
              {(TableRow) =>
                accidents.map((accident) => (
                  <TableRow onClick={onAccident(accident)}>
                    {(TableCol) =>
                      [
                        <TableCol key="datetime_of_loss">
                          {dateTime(accident.datetime_of_loss).format()}
                        </TableCol>,
                        displayDriver && (
                          <TableCol key="driver">
                            {!!accident.driver
                              ? `${accident.driver.first_name} ${accident.driver.last_name}`
                              : '-'}
                          </TableCol>
                        ),
                        displayVehicle && (
                          <TableCol key="vehicle">
                            {!!accident.vehicle ? accident.vehicle.svid : '-'}
                          </TableCol>
                        ),
                        <TableCol key="rideshare">
                          {accident.rideshare_signed_in_service || '-'}
                        </TableCol>,
                      ].filter((col) => !!col)
                    }
                  </TableRow>
                ))
              }
            </Table>
          </Column>
        </Row>
      </Container>
    </Content>
  </PageContainer>
);

AssociatedAccidentsContainer.propTypes = {
  loading: PropTypes.bool,
  displayDriver: PropTypes.bool,
  displayVehicle: PropTypes.bool,
  accidents: PropTypes.array,
  page: PropTypes.number,
  pages: PropTypes.number,
  onPage: PropTypes.func,
  onAccident: PropTypes.func,
};

export default AssociatedAccidentsContainer;
