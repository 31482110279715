import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import string from '@matthahn/sally-fw/lib/type/types/string.type';

export default attribute({
  type: SELECT,
  attribute: 'party_type',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Type',
    short: 'Type',
  },
  options: ['FIRST_PARTY', 'THIRD_PARTY', 'RIDESHARE'],
});
