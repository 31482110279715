const columns = (accident) =>
  [
    {
      key: 'driverStatement',
      label: 'Driver Statement',
    },
    {
      key: 'photos',
      label: 'Photos',
    },
    {
      key: 'charge',
      label: 'Charge',
    },
    accident?.tow_required && {
      key: 'receipt',
      label: 'Receipt',
    },
    {
      key: 'claim',
      label: 'Claim',
    },
  ].filter((column) => !!column);

export default columns;
