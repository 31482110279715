import {TOGGLE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'rf_check_rcvd',
  label: {
    default: 'Check RCVD',
    short: 'Check RCVD',
  },
});
