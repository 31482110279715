// accident redux actions
import {updateAccident as updateAccidentAct} from '../../../accident/redux/actions';

// accidentClaim api
import updateAccidentClaimApi from '../../api/update.api.accidentClaim';

// components
import {Select} from '@matthahn/sally-ui';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class AssigneeContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    users: PropTypes.array,
  };

  state = {
    assigned_user: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({assigned_user: this.assigned_user()});
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  userOptions = () =>
    [...this.props.users].map((user) => ({
      value: `${user.id}`,
      label: user.username,
    }));

  assigned_user = () => {
    const {accident} = this.props;
    const assigned_user = accident?.dolclaim?.assigned_user || null;
    return !!assigned_user ? `${assigned_user}` : null;
  };

  change = async (assigned_user) => {
    const {accident, dispatch} = this.props;
    this.setState({assigned_user});

    try {
      const accidentClaimBody = {
        assigned_user,
      };
      const updatedAccidentClaim = await updateAccidentClaimApi({
        accidentId: accident.id,
        claim: accidentClaimBody,
      });
      if (!this.mounted) return;
      const updatedAccident = {
        ...accident,
        dolclaim: updatedAccidentClaim,
      };
      dispatch(updateAccidentAct(updatedAccident));
    } catch (error) {
      // do nothing just don't fail
    }
  };

  render() {
    const {assigned_user} = this.state;
    return (
      <Select
        options={this.userOptions()}
        value={assigned_user}
        onChange={this.change}
        size="small"
      />
    );
  }
}

export default connect((state) => ({users: state.user.users}))(
  AssigneeContainer
);
