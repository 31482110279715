import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/create.event.transaction';

export default async (transaction, jwt = null) => {
  const api = f3tch('/payments/transactions/').profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.body(transaction).post();
  event.pub(response);
  return response;
};
