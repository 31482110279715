import {fileName, folder, date} from '../../../document';

export default folder({
  defaultFileName: 'Photo',
  // PHOTO - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `PHOTO - ${accident.driver?.first_name}_${accident.driver?.last_name} - ${
        accident?.medallion?.medallion_number || accident?.vehicle?.plate
      } - ${date()}`
  ),
  folderName: 'Photos',
  type: 'accidentPhoto',
});
