import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (ids = [], driver_email, message = null) => {
  const data = {ids, driver_email};
  if (!!message) data.message = message;
  return f3tch('/documents/document_email/')
    .profile(profile())
    .body(data)
    .post();
};
