// accident components
import AccidentValueTable from '../../../accident/components/AccidentValueTable/AccidentValueTable';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const AccidentPartyEditor = ({
  insurance_adjuster_contact_info_email,
  insurance_adjuster_contact_info_phone,
  insurance_adjuster_name,
  insurance_carrier,
  insurance_claim_number,
  onChange,
  party_type,
  rideshare_platform,
  saving,
}) => (
  <Fragment>
    {!!rideshare_platform && (
      <AccidentValueTable label="Platform" display={rideshare_platform} />
    )}
    <AccidentValueTable
      label={party_type.label.default}
      display={
        <AttributeInput
          value={party_type}
          placeholder={party_type.label.default}
          onChange={onChange}
          disabled={saving}
          notFilterable
          size="small"
          flat
        />
      }
      wrapContent
      noPadding
    />
    <AccidentValueTable
      label={insurance_adjuster_name.label.default}
      display={
        <AttributeInput
          value={insurance_adjuster_name}
          placeholder={insurance_adjuster_name.label.default}
          onChange={onChange}
          disabled={saving}
          size="small"
          flat
        />
      }
      wrapContent
      noPadding
    />
    <AccidentValueTable
      label={insurance_carrier.label.default}
      display={
        <AttributeInput
          value={insurance_carrier}
          onChange={onChange}
          disabled={saving}
          notFilterable
          size="small"
          flat
        />
      }
      wrapContent
      noPadding
    />
    <AccidentValueTable
      label={insurance_claim_number.label.default}
      display={
        <AttributeInput
          value={insurance_claim_number}
          placeholder={insurance_claim_number.label.default}
          onChange={onChange}
          disabled={saving}
          size="small"
          flat
        />
      }
      wrapContent
      noPadding
    />
    <AccidentValueTable
      label={insurance_adjuster_contact_info_phone.label.default}
      display={
        <AttributeInput
          value={insurance_adjuster_contact_info_phone}
          placeholder={insurance_adjuster_contact_info_phone.label.default}
          onChange={onChange}
          disabled={saving}
          size="small"
          flat
        />
      }
      wrapContent
      noPadding
    />
    <AccidentValueTable
      label={insurance_adjuster_contact_info_email.label.default}
      display={
        <AttributeInput
          value={insurance_adjuster_contact_info_email}
          placeholder={insurance_adjuster_contact_info_email.label.default}
          onChange={onChange}
          disabled={saving}
          size="small"
          flat
        />
      }
      wrapContent
      noPadding
    />
  </Fragment>
);

AccidentPartyEditor.propTypes = {
  insurance_adjuster_contact_info_email: PropTypes.object,
  insurance_adjuster_contact_info_phone: PropTypes.object,
  insurance_adjuster_name: PropTypes.object,
  insurance_carrier: PropTypes.object,
  insurance_claim_number: PropTypes.object,
  onChange: PropTypes.func,
  party_type: PropTypes.object,
  rideshare_platform: PropTypes.string,
  saving: PropTypes.bool,
};

export default AccidentPartyEditor;
