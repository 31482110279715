import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.accidentParty';

export default async (partyId, party) => {
  const response = await f3tch(`/accidents/dol/claim/update_party/${partyId}/`)
    .profile(profile())
    .body(party)
    .patch();
  event.publish(response);
  return response;
};
