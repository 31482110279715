const mainTableColumns = [
  {
    key: 'id',
    label: 'ID',
    span: 0.5,
    sortable: true,
  },
  {
    key: 'datetime_of_loss',
    label: 'Date',
    sortable: true,
  },
  {
    key: 'driver',
    label: 'Driver',
  },
  {
    key: 'vehicle',
    label: 'Vehicle',
  },
  {
    key: 'insurance',
    label: 'Insurance',
  },
  {
    key: 'demandAmount',
    label: 'Demand Amount',
  },
  {
    key: 'amountReceived',
    label: 'Amount Received',
  },
  {
    key: 'deductibleAmountCharged',
    label: 'Deductible Amount Charged',
  },
  {
    key: 'state',
    label: 'State',
  },
  {
    key: 'assignee',
    label: 'Assignee',
  },
];

export default mainTableColumns;
