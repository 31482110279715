import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
import createAccidentApi from '../../api/create.api.accident';

// Attributes
import appointmentTimeAttr from '../../attributes/appointment_time.attribute.accident';
import cameraUnpluggedAttr from '../../attributes/camera_unplugged.attribute.accident';
import dateTimeOfLossAttr from '../../attributes/datetime_of_loss.attribute.accident';
import descriptionAttr from '../../attributes/description.attribute.accident';
import dolCityAttr from '../../attributes/dol_city.attribute.accident';
import dolLocationAttr from '../../attributes/dol_location.attribute.accident';
import dolStateAttr from '../../attributes/dol_state.attribute.accident';
import driverAttr from '../../attributes/driver.attribute.accident';
import medallionAttr from '../../../accident/attributes/medallion.attribute.accident';
import policeCalledAttr from '../../attributes/police_called.attribute.accident';
import policeReportTakenAttr from '../../attributes/police_report_taken.attribute.accident';
import rentalAttr from '../../attributes/rental.attribute.accident';
import rideShareSignedInPeriodAttr from '../../attributes/rideshare_signed_in_period.attribute.accident';
import rideShareSignedInServiceAttr from '../../attributes/rideshare_signed_in_service.attribute.accident';
import stateAttr from '../../attributes/state.attribute.accident';
import towRequiredAttr from '../../attributes/tow_required.attribute.accident';
import vehicleAttr from '../../attributes/vehicle.attribute.accident';

// Components
import CreateAccidentStep from '../../components/CreateAccidentStep/CreateAccidentStep';

// Dates
import {parseISO} from 'date-fns';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Prep
import createAccidentPrep from '../../preparation/create.preparation.accident';

// Routes
import accidentRoute from '../../pages/AccidentPage/route';

class CreateAccidentStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.any,
    visible: PropTypes.bool,
    accident: PropTypes.object,
    driver: PropTypes.object,
    rental: PropTypes.object,
    goingBack: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    onDisabled: PropTypes.func,
    onClose: PropTypes.func,
    onComplete: PropTypes.func,
    onBack: PropTypes.func,

    history: PropTypes.object,
  };

  state = {
    loading: false,

    appointment_time: appointmentTimeAttr(''),
    camera_unplugged: cameraUnpluggedAttr(false),
    datetime_of_loss: dateTimeOfLossAttr(''),
    description: descriptionAttr(''),
    dol_city: dolCityAttr(''),
    dol_location: dolLocationAttr(''),
    dol_state: dolStateAttr(''),
    police_called: policeCalledAttr(false),
    police_report_taken: policeReportTakenAttr(false),
    rideshare_signed_in_period: rideShareSignedInPeriodAttr(''),
    rideshare_signed_in_service: rideShareSignedInServiceAttr(''),
    tow_required: towRequiredAttr(false),
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const {accident} = this.props;

    this.setState({
      appointment_time: accident?.appointment_time || appointmentTimeAttr(''),
      camera_unplugged:
        accident?.camera_unplugged || cameraUnpluggedAttr(false),
      datetime_of_loss: accident?.datetime_of_loss || dateTimeOfLossAttr(''),
      description: accident?.description || descriptionAttr(''),
      dol_city: accident?.dol_city || dolCityAttr(''),
      dol_location: accident?.dol_location || dolLocationAttr(''),
      dol_state: accident?.dol_state || dolStateAttr(''),
      police_called: accident?.police_called || policeCalledAttr(false),
      police_report_taken:
        accident?.police_report_taken || policeReportTakenAttr(false),
      rideshare_signed_in_period:
        accident?.rideshare_signed_in_period || rideShareSignedInPeriodAttr(''),
      rideshare_signed_in_service:
        accident?.rideshare_signed_in_service ||
        rideShareSignedInServiceAttr(''),
      tow_required: accident?.tow_required || towRequiredAttr(false),
    });
  };

  onBack = () => {
    const {onBack} = this.props;
    const {
      loading,
      appointment_time,
      camera_unplugged,
      datetime_of_loss,
      description,
      dol_city,
      dol_location,
      dol_state,
      police_called,
      police_report_taken,
      rideshare_signed_in_period,
      rideshare_signed_in_service,
      tow_required,
    } = this.state;
    if (loading) return;
    const accident = {
      appointment_time,
      camera_unplugged,
      datetime_of_loss,
      description,
      dol_city,
      dol_location,
      dol_state,
      police_called,
      police_report_taken,
      rideshare_signed_in_period,
      rideshare_signed_in_service,
      tow_required,
    };
    onBack({accident});
  };

  onChange = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  create = async () => {
    const {driver, rental, onComplete, onDisabled, history} = this.props;
    const {
      loading,
      appointment_time,
      camera_unplugged,
      datetime_of_loss,
      description,
      dol_city,
      dol_location,
      dol_state,
      police_called,
      police_report_taken,
      rideshare_signed_in_period,
      rideshare_signed_in_service,
      tow_required,
    } = this.state;

    if (loading) return;

    onDisabled(true);
    this.setState({loading: true});

    try {
      const accident = await createAccidentPrep({
        appointment_time,
        camera_unplugged,
        datetime_of_loss,
        description,
        dol_city,
        dol_location,
        dol_state,
        police_called,
        police_report_taken,
        rideshare_signed_in_period,
        rideshare_signed_in_service,
        tow_required,

        state: stateAttr('pending_4x4'),

        driver: driverAttr(driver.id),
        medallion: medallionAttr(rental.medallion.id),
        rental: rentalAttr(rental.id),
        vehicle: vehicleAttr(rental.vehicle.id),
      });
      accident.rideshare_signed_in =
        !!accident.rideshare_signed_in_service &&
        !!accident.rideshare_signed_in_service.trim().length;
      const newAccident = await createAccidentApi(accident);
      alert.success('Accident created');
      onComplete();
      history.push(accidentRoute(newAccident.id));
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      onDisabled(false);
      this.setState({loading: false});
    }
  };

  disabledDates = () => {
    const {rental} = this.props;
    return !rental
      ? []
      : [
          {before: parseISO(rental.start_date)},
          !!rental.end_date && {after: parseISO(rental.end_date)},
        ].filter((date) => !!date);
  };

  render() {
    const {Container, rental, driver} = this.props;
    const {
      loading,
      appointment_time,
      camera_unplugged,
      datetime_of_loss,
      description,
      dol_city,
      dol_location,
      dol_state,
      police_called,
      police_report_taken,
      rideshare_signed_in_period,
      rideshare_signed_in_service,
      tow_required,
    } = this.state;
    return (
      <CreateAccidentStep
        Container={Container}
        loading={loading}
        branch={rental?.sally_branch || driver?.sally_branch}
        appointment_time={appointment_time}
        camera_unplugged={camera_unplugged}
        datetime_of_loss={datetime_of_loss}
        description={description}
        dol_city={dol_city}
        dol_location={dol_location}
        dol_state={dol_state}
        police_called={police_called}
        police_report_taken={police_report_taken}
        rideshare_signed_in_period={rideshare_signed_in_period}
        rideshare_signed_in_service={rideshare_signed_in_service}
        tow_required={tow_required}
        disabledDates={this.disabledDates()}
        onChange={this.onChange}
        onCreate={this.create}
        onBack={this.onBack}
      />
    );
  }
}

export default withRouter(CreateAccidentStepContainer);
