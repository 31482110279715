// date lib
import {isBefore, isDate, parseISO} from 'date-fns';

const isDateInPast = ({dateToCompare, referenceDate}) => {
  const dateToCompareObject = isDate(dateToCompare)
    ? dateToCompare
    : parseISO(dateToCompare);
  const referenceDateObject = isDate(referenceDate)
    ? referenceDate
    : parseISO(referenceDate);
  return isBefore(dateToCompareObject, referenceDateObject);
};

export default isDateInPast;
