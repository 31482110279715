import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Icon} from '@matthahn/sally-ui';
import Container from './components/Container';

const Loader = ({full, padding}) => (
  <Container full={full} padding={padding}>
    <Icon icon="loading2" spin />
  </Container>
);

Loader.propTypes = {
  full: PropTypes.bool,
  padding: PropTypes.bool,
};

Loader.defaultProps = {
  full: false,
  padding: false,
};

export default Loader;
