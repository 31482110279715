import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'rideshare_signed_in_service',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Rideshare',
    short: 'Rideshare',
  },
  options: [
    "UBER", "UBEREATS", "LYFT", "DOORDASH", "GRUBHUB", "INSTACART", "AMAZON"
  ],
});
