import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Button, Info} from '@matthahn/sally-ui';

// local components
import ButtonsColumns from './components/ButtonsColumns';
import ButtonsRow from './components/ButtonsRow';

const AccidentsClaimCard = ({
  accident,
  claiming,
  visible,
  onClose,
  onClaim,
  onNoClaim,
  onDeleteNoClaim,
}) => (
  <Modal visible={visible} onClose={onClose} title="Claims" icon="warning">
    {(Content) =>
      !!accident?.noclaim_reason ? (
        <Content padding="none">
          <Info
            type="warning"
            loading={claiming}
            action={{onClick: onDeleteNoClaim, label: 'Undo'}}
            flat
          >
            Not required! Reason: {accident?.noclaim_reason?.text}
          </Info>
        </Content>
      ) : (
        <Content>
          <ButtonsRow>
            <ButtonsColumns>
              <Button
                onClick={onClaim}
                theme="orange"
                loading={claiming}
                icon="file1"
                block
              >
                File claim
              </Button>
            </ButtonsColumns>
            <ButtonsColumns>
              <Button onClick={onNoClaim} theme="grey" icon="close" block>
                No claim
              </Button>
            </ButtonsColumns>
          </ButtonsRow>
        </Content>
      )
    }
  </Modal>
);

AccidentsClaimCard.propTypes = {
  accident: PropTypes.object,
  claiming: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onClaim: PropTypes.func,
  onNoClaim: PropTypes.func,
  onDeleteNoClaim: PropTypes.func,
};

export default AccidentsClaimCard;
