// accident components
import AccidentValueTable from '../../../accident/components/AccidentValueTable/AccidentValueTable';

// components
import {Modal} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FaultEditorModal = ({
  fault_distribution,
  fault_reason,
  onChange,
  onClose,
  onSave,
  saving,
  visible,
}) => (
  <Modal
    title="File Claim"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Save', loading: saving, onClick: onSave, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Content padding="none" noBorder>
        <AccidentValueTable
          label={fault_distribution.label.default}
          display={
            <AttributeInput
              value={fault_distribution}
              onChange={onChange}
              disabled={saving}
              size="small"
              notFilterable
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={fault_reason.label.default}
          display={
            <AttributeInput
              value={fault_reason}
              onChange={onChange}
              disabled={saving}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
      </Content>
    )}
  </Modal>
);

FaultEditorModal.propTypes = {
  fault_distribution: PropTypes.object,
  fault_reason: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  visible: PropTypes.bool,
};

export default FaultEditorModal;
