// accident lib
import accidentState from '../state';
import getState from './getState.lib.accident';

// types
import capitalLettersType from '@matthahn/sally-fw/lib/type/types/capitalLetters.type';

const getAccidentStateObject = (accident) =>
  !!accident?.accidentstate?.manual_state
    ? {
        key: accident?.accidentstate?.manual_state,
        label: capitalLettersType(
          `${accident?.accidentstate?.manual_state}`.split('_').join(' ')
        ).format(),
        color: accident?.accidentstate?.manual_state.startsWith('closed')
          ? 'green'
          : 'darkGrey',
      }
    : accidentState(getState(accident));

export default getAccidentStateObject;
